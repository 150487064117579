.rdp-composer-ars-converter {

}

.rdp-composer-ars-converter-header {
  width: 100%;
  background: #ebebeb;
  height: 80px;
  box-shadow: 0px 0px 10px #919191;
  position: absolute;
  top: 0px;
  z-index: 999;
}

.rdp-composer-ars-converter-header-title {
  font-weight: bold;
  text-transform: uppercase;
  line-height: 80px;
  padding-left: 20px;
}

.rdp-composer-ars-converter-header-upload-btn {
  position: absolute;
  padding: 8px 40px 8px 20px;
  top: 24px;
  color: #5d5d5d;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  left: 140px;
  border: 1px solid #CCC;
  border-radius: 110px;
  background-image: url("./icon-file.svg");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: calc(100% - 12px);
}

.rdp-composer-ars-converter-header-submit-btn {
  position: absolute;
  right: 200px;
  padding: 13px 25px;
  top: 20px;
  background: #2d7b45;
  color: #FFF;
  text-transform: uppercase;
  border: 0;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
}

.rdp-composer-ars-converter-header-upload-input {
  display: none;
}

.rdp-composer-ars-converter-sidebar {
  position: absolute;
  top: 80px;
  bottom: 0px;
  left: 0px;
  width: 550px;
  border-right: 1px solid #e9e9e9;
  background: #f7f7f7;
  overflow-x: hidden;
  overflow-y: auto;
}

.rdp-composer-ars-converter-sidebar-header {
  width: 100%;
  text-align: center;
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  background: #fff;
  border-bottom: 1px solid #e9e9e9;
  color: #5d5d5d;
}

.rdp-composer-ars-converter-sidebar-icon svg {
  width: 60px;
  height: 60px;
  display: block;
  margin: auto;
  margin-top: 70px;
  opacity: 0.5;
}

.rdp-composer-ars-converter-sidebar-message {
  display: block;
  margin-top: 10px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  color: #7c7c7c;
}

.rdp-composer-ars-converter-sidebar ul li {
  /* margin: 12px;
  background: #ffffff;
  box-shadow: 0px 0px 3px #a5a5a5;
  padding: 12px;
  padding-right: 50px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  word-break: break-all; */
}

.rdp-composer-ars-converter-sidebar .rdp-composer-sidebar-subitem {
  box-shadow: 0px 0px 5px #cbcbcb;
  margin-bottom: 0px;
}

.rdp-composer-ars-converter-compositions {
  position: absolute;
  top: 80px;
  bottom: 0px;
  left: 550px;
  right: -1px;
  border-right: 1px solid #e9e9e9;
  background: #f7f7f7;
  overflow-x: hidden;
  overflow-y: auto;
}

.rdp-composer-ars-converter-compositions-header {
  width: 100%;
  text-align: center;
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  background: #fff;
  border-bottom: 1px solid #e9e9e9;
  color: #5d5d5d;
}

.rdp-composer-ars-converter-compositions-items {
  position: absolute;
  top: 60px;
  bottom: 60px;
  left: 0px;
  right: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}

.rdp-composer-ars-converter-compositions-items li {
  position: relative;
  margin: 12px;
  background: #ffffff;
  box-shadow: 0px 0px 3px #a5a5a5;
  padding: 20px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  word-break: break-word;
}

.rdp-composer-ars-converter-compositions-items li.active {
  background: #c5e5cf;
}

.rdp-composer-ars-converter-compositions-item-icon-code {
  width: 54px;
  height: 54px;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  background-image: url("./icon-code.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.rdp-composer-ars-converter-compositions-actions {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 5px;
}

.rdp-composer-ars-converter-compositions-actions-btn {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  text-align: center;
  background-color: #764ab0;
  color: #FFF;
  text-transform: uppercase;
  font-weight: bold;
  border: 0;
  cursor: pointer;
}

.rdp-composer-ars-converter-compositions-uploading {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #000000b8;
}

.rdp-composer-ars-converter-compositions-uploading-modal {
  width: 480px;
  background: #fff;
  position: absolute;
  left: 50%;
  margin-left: -240px;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 4px;
  padding: 30px;
}

.rdp-composer-ars-converter-compositions-uploading-label {
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  position: relative;
}

.rdp-composer-ars-converter-compositions-uploading-percentage-box {
  margin-top: 25px;
  width: 100%;
  height: 60px;
  background: #bbb;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.rdp-composer-ars-converter-compositions-uploading-percentage-bar {
  height: 60px;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background-color: #764ab0;
  transition: all 0.2s linear;
}

.rdp-composer-ars-converter-compositions-uploading-percentage-label {
  width: 100%;
  height: 60px;
  text-align: center;
  display: block;
  line-height: 60px;
  font-weight: bold;
  position: absolute;
  z-index: 1;
  color: #FFF;
  text-shadow: 0px 0px 17px #545454;
}

.rdp-composer-ars-converter-compositions-uploading-message {
  color: #764ab0;
  background: #f1e7ff;
  border-radius: 4px;
  border: 1px solid #c5a4f0;
  font-size: 12px;
  padding: 10px;
  margin-top: 10px;
}

.rdp-composer-ars-converter-compositions-uploading-close-btn {
  display: block;
  margin: auto;
  background: #764ab0;
  color: #FFF;
  font-weight: bold;
  text-transform: uppercase;
  border: 0;
  font-size: 10px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  border-radius: 4px;
}

.rdp-composer-ars-converter-structure {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: #FFF;
  z-index: 99999;
  overflow-x: hidden;
  overflow-y: auto;
}

.rdp-composer-ars-converter-structure-header {
  width: 100%;
  text-align: center;
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  background: #fff;
  border-bottom: 1px solid #e9e9e9;
  color: #5d5d5d;
}

.rdp-composer-ars-converter-structure-header-tab {
  height: 60px;
  display: inline-block;
  text-transform: uppercase;
  padding: 0px 15px;
  cursor: pointer;
  font-size: 14px;
  color: #5d5d5d;
}

.rdp-composer-ars-converter-structure-header-tab.active {
  border-bottom: 4px solid #764ab0;
  color: #764ab0;
  height: 59px;
}

.rdp-composer-ars-converter-structure-header-close {
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0;
  cursor: pointer;
  background-image: url("./icon-close.svg");
  background-position: center;
  background-size: 24px;
  background-repeat: no-repeat;
}

.rdp-composer-ars-converter-structure-json {
  padding: 15px;
  position: absolute;
  top: 60px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow-x: hidden;
  overflow-y: auto;
}