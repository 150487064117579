.rdp-composer-header-preview {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  background: #fff;
  border-bottom: 1px solid #f3f3f3;
  overflow: hidden;
}

.rdp-composer-header-preview-back::before {
  content: "";
  mask: url(./left.svg) no-repeat 50% 50%;
  mask-size: 40%;
  width: 30px;
  height: 80px;
  display: inline-block;
  vertical-align: top;
  background: #2d7b45;
}

.rdp-composer-header-preview-back {
  position: absolute;
  left: 10px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 80px;
  color: #2d7b45;
  cursor: pointer;
}

.rdp-composer-header-preview-back-embed::before {
  content: "";
  mask: url(./left.svg) no-repeat 50% 50%;
  mask-size: 40%;
  width: 30px;
  height: 80px;
  display: inline-block;
  vertical-align: top;
  background: #764ab0;
}

.rdp-composer-header-preview-back-embed {
  position: absolute;
  left: 10px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 80px;
  color: #764ab0;
  cursor: pointer;
}

.rdp-composer-header-preview ul {
  text-align: center;
}

.rdp-composer-header-preview ul li {
  display: inline-block;
  width: 80px;
  height: 80px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.rdp-composer-header-preview ul li.active {
  background: #ececec;
}

.rdp-composer-header-preview ul li.active::before {
  background: #2e7a45;
}

.rdp-composer-header-preview ul li::before {
  content: "";
  width: 80px;
  height: 80px;
  display: block;
  background: #636363;
  transition: all 0.2s ease-in-out;
}

.rdp-composer-header-preview ul li.rdp-composer-header-preview-mobile-icon::before {
  mask: url(./mobile.svg) no-repeat 50% 50%;
  mask-size: 30%;
}

.rdp-composer-header-preview ul li.rdp-composer-header-preview-tablet-icon::before {
  mask: url(./tablet.svg) no-repeat 50% 50%;
  mask-size: 40%;
}

.rdp-composer-header-preview ul li.rdp-composer-header-preview-desktop-icon::before {
  mask: url(./desktop.svg) no-repeat 50% 50%;
  mask-size: 40%;
}

.rdp-composer-header-preview ul li.active.embed::before {
  background: #764ab0;
}