/* DARK CONFIGS*/
@import url("../../colors.css");

.rdp-composer-element-edit-modal-theme-dark {
  background: var(--card);
  color: var(--greyText);
}
.rdp-composer-element-edit-modal-theme-dark .rdp-composer-element-edit-header-template-picker-btn-info {
  background-image: url("./icon-info-dark.svg");
}
.rdp-composer-element-edit-modal-theme-dark .rdp-composer-element-edit-header-close {
  background: #fff;
}

.rdp-composer-element-edit-modal-theme-dark .element-tabs-properties-item:hover {
  background: var(--greyback);
}
.rdp-composer-element-edit-modal-theme-dark .rdp-composer-element-edit-header-title,
.rdp-composer-element-edit-modal-theme-dark .rdp-composer-element-edit-header-template-picker-btn-label,
.rdp-composer-element-edit-modal-theme-dark .rdp-composer-element-edit-tabs-item.active,
.rdp-composer-element-edit-theme-dark .element-tabs-properties-item,
.rdp-composer-element-edit-theme-dark .element-tabs-properties-item-upload-video,
.rdp-composer-element-edit-theme-dark .element-tabs-properties-item-upload-image,
.rdp-composer-element-edit-theme-dark .element-tabs-properties-item-label,
.rdp-composer-element-edit-theme-dark .element-accordion-properties-item-label,
.rdp-composer-element-edit-theme-dark .element-accordion-properties-item-upload-video,
.rdp-composer-element-edit-theme-dark .element-accordion-properties-item-upload-image,
.rdp-composer-element-edit-theme-dark [data-rbd-drag-handle-context-id="2"], 
.rdp-composer-element-edit-theme-dark svg
{
  color: var(--greyText) !important;
  fill: var(--greyText) !important;
}

.rdp-composer-element-edit-theme-dark .element-accordion-properties-item-upload-video::before {
    background-image: url('./icon-video-dark.svg');
}
.rdp-composer-element-edit-theme-dark .element-accordion-properties-item-upload-image::before {
    background-image: url('./icon-image-dark.svg');
}
.rdp-composer-element-edit-theme-dark .element-accordion-properties-item-remove,
.rdp-composer-element-edit-theme-dark .element-list-properties-item-remove,
.rdp-composer-element-edit-theme-dark .element-tabs-properties-item-remove
{
    background-image: url('./icon-remove-dark.svg');
}

.rdp-composer-element-edit-theme-dark .element-accordion-properties-item:hover, 
.rdp-composer-element-edit-theme-dark .rdp-composer-element-edit-select {
    background: var(--card) !important;
    color: var(--greyText) !important;
    border: 1px solid #cccccc46;
}
.rdp-composer-element-edit-modal-theme-dark input,
.rdp-composer-element-edit-modal-theme-dark .rdp-composer-font-picker-select {
  background: var(--greyback);
  color: var(--greyText);
  border: 1px solid #cccccc46;
}

.rdp-composer-element-edit-modal-theme-dark .rdp-composer-element-edit-tabs {
  box-shadow: 0 3px 5px 0px var(--card);
}
