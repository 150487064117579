* {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

input:focus, textarea:focus {
  outline:none;
}

*:focus, *:active, * { outline:0 !important; -webkit-tap-highlight-color: transparent;}


@font-face {
  font-family: BradescoSans-Regular;
  src: url("./assets/fonts/BradescoSans-Regular.ttf");
}

@font-face {
  font-family: BradescoSans-Bold;
  src: url("./assets/fonts/BradescoSans-Bold.ttf");
}

@font-face {
  font-family: TitilliumWeb-Regular;
  src: url("./assets/fonts/TitilliumWeb-Regular.ttf");
}

@font-face {
  font-family: Merriweather-Regular;
  src: url("./assets/fonts/Merriweather-Regular.ttf");
}

@font-face {
  font-family: Merriweather-Bold;
  src: url("./assets/fonts/Merriweather-Bold.ttf");
}

@font-face {
  font-family: Lato-Regular;
  src: url("./assets/fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: Lato-Bold;
  src: url("./assets/fonts/Lato-Bold.ttf");
}

@font-face {
  font-family: Prompt-Regular;
  src: url("./assets/fonts/Prompt-Regular.ttf");
}

@font-face {
  font-family: Prompt-Bold;
  src: url("./assets/fonts/Prompt-Bold.ttf");
}

@font-face {
  font-family: OpenSans-Regular;
  src: url("./assets/fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: OpenSansCondensed-Light;
  src: url("./assets/fonts/OpenSansCondensed-Light.ttf");
}

@font-face {
  font-family: OpenSansCondensed-Bold;
  src: url("./assets/fonts/OpenSansCondensed-Bold.ttf");
}

@font-face {
  font-family: OpenSans-Bold;
  src: url("./assets/fonts/OpenSans-Bold.ttf");
}

@font-face {
  font-family: ProductSans-Bold;
  src: url("./assets/fonts/ProductSans-Bold.ttf");
}

@font-face {
  font-family: ProductSans-Regular;
  src: url("./assets/fonts/ProductSans-Regular.ttf");
}

@font-face {
  font-family: Roboto-Regular;
  src: url("./assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: Roboto-Bold;
  src: url("./assets/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: RobotoCondensed-Regular;
  src: url("./assets/fonts/RobotoCondensed-Regular.ttf");
}

@font-face {
  font-family: RobotoCondensed-Bold;
  src: url("./assets/fonts/RobotoCondensed-Bold.ttf");
}

@font-face {
  font-family: RobotoSlab-Regular;
  src: url("./assets/fonts/RobotoSlab-Regular.ttf");
}

@font-face {
  font-family: RobotoSlab-Bold;
  src: url("./assets/fonts/RobotoSlab-Bold.ttf");
}

@font-face {
  font-family: Lora-Regular;
  src: url("./assets/fonts/Lora-Regular.ttf");
}

@font-face {
  font-family: Lora-Bold;
  src: url("./assets/fonts/Lora-Bold.ttf");
}

@font-face {
  font-family: Montserrat-Regular;
  src: url("./assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: Montserrat-Bold;
  src: url("./assets/fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: Ubuntu-Regular;
  src: url("./assets/fonts/Ubuntu-Regular.ttf");
}

@font-face {
  font-family: Ubuntu-Bold;
  src: url("./assets/fonts/Ubuntu-Bold.ttf");
}

@font-face {
  font-family: ProximaNova-Regular;
  src: url("./assets/fonts/ProximaNova-Regular.ttf");
}

@font-face {
  font-family: ProximaNova-Bold;
  src: url("./assets/fonts/ProximaNova-Bold.ttf");
}

@font-face {
  font-family: Grueber-Regular;
  src: url("./assets/fonts/Grueber-Regular.ttf");
}

@font-face {
  font-family: Grueber-Bold;
  src: url("./assets/fonts/Grueber-Bold.ttf");
}

@font-face {
  font-family: HelveticaNeue-Regular;
  src: url("./assets/fonts/HelveticaNeue-Regular.ttf");
}

@font-face {
  font-family: HelveticaNeue-Bold;
  src: url("./assets/fonts/HelveticaNeue-Bold.ttf");
}

@font-face {
  font-family: Objective-Regular;
  src: url("./assets/fonts/Objective-Regular.otf");
}

@font-face {
  font-family: Objective-Bold;
  src: url("./assets/fonts/Objective-Bold.otf");
}

@font-face {
  font-family: ItauDisplayPro-Regular;
  src: url("./assets/fonts/ItauDisplayPro-Regular.ttf");
}

@font-face {
  font-family: ItauDisplayPro-Bold;
  src: url("./assets/fonts/ItauDisplayPro-Bold.ttf");
}

@font-face {
  font-family: ItauTextPro-Regular;
  src: url("./assets/fonts/ItauTextPro-Regular.ttf");
}

@font-face {
  font-family: ItauTextPro-Bold;
  src: url("./assets/fonts/ItauTextPro-Bold.ttf");
}

@font-face {
  font-family: Inter-Black;
  src: url("./assets/fonts/Inter-Black.ttf");
}

@font-face {
  font-family: Inter-Bold;
  src: url("./assets/fonts/Inter-Bold.ttf");
}

@font-face {
  font-family: Inter-ExtraBold;
  src: url("./assets/fonts/Inter-ExtraBold.ttf");
}

@font-face {
  font-family: Inter-ExtraLight;
  src: url("./assets/fonts/Inter-ExtraLight.ttf");
}

@font-face {
  font-family: Inter-Light;
  src: url("./assets/fonts/Inter-Light.ttf");
}

@font-face {
  font-family: Inter-Medium;
  src: url("./assets/fonts/Inter-Medium.ttf");
}

@font-face {
  font-family: Inter-Regular;
  src: url("./assets/fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: Inter-SemiBold;
  src: url("./assets/fonts/Inter-SemiBold.ttf");
}

@font-face {
  font-family: Inter-Thin;
  src: url("./assets/fonts/Inter-Thin.ttf");
}

@font-face {
  font-family: Sharp-Sans;
  src: url("./assets/fonts/Sharp_Sans/Sharp-Sans.otf");
}

@font-face {
  font-family: Sharp-Sans-Thin;
  src: url("./assets/fonts/Sharp_Sans/Sharp-Sans-Thin.otf");
}

@font-face {
  font-family: Sharp-Sans-Thin-Italic;
  src: url("./assets/fonts/Sharp_Sans/Sharp-Sans-Thin-Italic.otf");
}

@font-face {
  font-family: Sharp-Sans-Semibold;
  src: url("./assets/fonts/Sharp_Sans/Sharp-Sans-Semibold.otf");
}

@font-face {
  font-family: Sharp-Sans-Semibold-Italic;
  src: url("./assets/fonts/Sharp_Sans/Sharp-Sans-Semibold-Italic.otf");
}

@font-face {
  font-family: Sharp-Sans-Medium-Italic;
  src: url("./assets/fonts/Sharp_Sans/Sharp-Sans-Medium-Italic.otf");
}

@font-face {
  font-family: Sharp-Sans-Medium;
  src: url("./assets/fonts/Sharp_Sans/Sharp-Sans-Medium.otf");
}

@font-face {
  font-family: Sharp-Sans-Light;
  src: url("./assets/fonts/Sharp_Sans/Sharp-Sans-Light.otf");
}

@font-face {
  font-family: Sharp-Sans-Light-Italic;
  src: url("./assets/fonts/Sharp_Sans/Sharp-Sans-Light-Italic.otf");
}

@font-face {
  font-family: Sharp-Sans-Italic;
  src: url("./assets/fonts/Sharp_Sans/Sharp-Sans-Italic.otf");
}

@font-face {
  font-family: Sharp-Sans-Extrabold;
  src: url("./assets/fonts/Sharp_Sans/Sharp-Sans-Extrabold.otf");
}

@font-face {
  font-family: Sharp-Sans-Extrabold-Italic;
  src: url("./assets/fonts/Sharp_Sans/Sharp-Sans-ExtraBold-Italic.otf");
}

@font-face {
  font-family: Sharp-Sans-Bold;
  src: url("./assets/fonts/Sharp_Sans/Sharp-Sans-Bold.otf");
}

@font-face {
  font-family: Sharp-Sans-Bold-Italic;
  src: url("./assets/fonts/Sharp_Sans/Sharp-Sans-Bold-Italic.otf");
}

@font-face {
  font-family: Duplicate-Ionic;
  src: url("./assets/fonts/duplicate/DuplicateIonic.otf");
}

@font-face {
  font-family: Duplicate-Ionic-Bold;
  src: url("./assets/fonts/duplicate/DuplicateIonicBold.otf");
}

@font-face {
  font-family: Duplicate-Ionic-Bold-Italic;
  src: url("./assets/fonts/duplicate/DuplicateIonicBoldItalic.otf");
}

@font-face {
  font-family: Duplicate-Ionic-Heavy;
  src: url("./assets/fonts/duplicate/DuplicateIonicHeavy.otf");
}

@font-face {
  font-family: Duplicate-Ionic-Heavy-Italic;
  src: url("./assets/fonts/duplicate/DuplicateIonicHeavyItalic.otf");
}

@font-face {
  font-family: Duplicate-Ionic-Italic;
  src: url("./assets/fonts/duplicate/DuplicateIonicItalic.otf");
}

@font-face {
  font-family: Duplicate-Ionic-Light;
  src: url("./assets/fonts/duplicate/DuplicateIonicLight.otf");
}

@font-face {
  font-family: Duplicate-Ionic-Light-Italic;
  src: url("./assets/fonts/duplicate/DuplicateIonicLightItalic.otf");
}

@font-face {
  font-family: Duplicate-Ionic-Medium;
  src: url("./assets/fonts/duplicate/DuplicateIonicMedium.otf");
}

@font-face {
  font-family: Duplicate-Ionic-Medium-Italic;
  src: url("./assets/fonts/duplicate/DuplicateIonicMediumItalic.otf");
}

@font-face {
  font-family: Duplicate-Ionic-Thin;
  src: url("./assets/fonts/duplicate/DuplicateIonicThin.otf");
}

@font-face {
  font-family: Duplicate-Ionic-Thin-Italic;
  src: url("./assets/fonts/duplicate/DuplicateIonicThinItalic.otf");
}

@font-face {
  font-family: Flama;
  src: url("./assets/fonts/flama/flama.otf");
}

@font-face {
  font-family: Flama-Bold;
  src: url("./assets/fonts/flama/flama-bold.otf");
}

@font-face {
  font-family: Flama-Condensed-Bold;
  src: url("./assets/fonts/flama/FlamaCondensed-Bold.otf");
}

@font-face {
  font-family: Flama-Condensed-Light;
  src: url("./assets/fonts/flama/FlamaCondensed-Light.otf");
}

body {
  margin: 0;
  background-color: #FFFFFF;
  font-family: TitilliumWeb-Regular;
}

h3 {
  margin: 0;
}

.ql-bubble .ql-tooltip.ql-flip {
  margin-top: 15px;
}

.ql-toolbar {
  padding: 0;
}

.ql-bubble .ql-toolbar .ql-formats {
  margin: 2px 10px 2px 0px!important;
}

.ql-bubble .ql-tooltip.ql-flip {
  top: 0!important;
  position: fixed!important;
  left: 0!important;
  right: 0!important;
  width: 100%!important;
  border-radius: 0!important;
  margin-top: 10px!important;
  z-index: 9999!important;
  padding: 26px 20px!important;
}

.ql-bubble .ql-tooltip.ql-flip .ql-tooltip-arrow {
  display: none;
}

/* ["15px", "18px", "20px", "22px", "32px"] */


.ql-bubble .ql-picker.ql-size .ql-picker-label::before,
.ql-bubble .ql-picker.ql-size .ql-picker-item::before,
.ql-bubble .ql-picker.ql-expanded .ql-picker-label::before {
  content: attr(data-value)!important;
}

.ql-bubble .ql-picker-options {
  max-height: 300px!important;
  overflow: hidden;
  overflow-y: inherit;
}

.ql-bubble .ql-picker-options .ql-picker-item[data-value="15px"]::before {
  content: '15 px'!important;
}

.ql-bubble .ql-picker-options .ql-picker-item[data-value="18px"]::before {
  content: '18 px'!important;
}

.ql-bubble .ql-picker-options .ql-picker-item[data-value="20px"]::before {
  content: '20 px'!important;
}

.ql-bubble .ql-picker-options .ql-picker-item[data-value="22px"]::before {
  content: '22 px'!important;
}

.ql-bubble .ql-picker-options .ql-picker-item[data-value="26px"]::before {
  content: '26 px'!important;
}

.ql-bubble .ql-tooltip {
  background-color: #fff!important;
  border-bottom: 1px solid #f3f3f3;
  text-align: center;
}

.ql-bubble .ql-stroke {
  stroke: #606060!important;
}

.ql-bubble .ql-fill, .ql-bubble .ql-stroke.ql-fill {
  fill: #606060!important;
}

.ql-bubble .ql-picker {
  color: #606060!important;
}

.ql-bubble.ql-toolbar button:hover, .ql-bubble .ql-toolbar button:hover, .ql-bubble.ql-toolbar button:focus, .ql-bubble .ql-toolbar button:focus, .ql-bubble.ql-toolbar button.ql-active, .ql-bubble .ql-toolbar button.ql-active, .ql-bubble.ql-toolbar .ql-picker-label:hover, .ql-bubble .ql-toolbar .ql-picker-label:hover, .ql-bubble.ql-toolbar .ql-picker-label.ql-active, .ql-bubble .ql-toolbar .ql-picker-label.ql-active, .ql-bubble.ql-toolbar .ql-picker-item:hover, .ql-bubble .ql-toolbar .ql-picker-item:hover, .ql-bubble.ql-toolbar .ql-picker-item.ql-selected, .ql-bubble .ql-toolbar .ql-picker-item.ql-selected {
  color: #2a2929!important;
}

.ql-toolbar {
  transition: none;
}

/* .ql-bubble .ql-picker.ql-size .ql-picker-label::before, 
.ql-bubble .ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value);
} */

.rdp-element-text .ql-editor span,
.rdp-element-text .ql-editor strong {
  line-height: 120%;
}

.ql-bubble .ql-tooltip.ql-editing .ql-tooltip-editor {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 80px;
  background: #ffffff;
  z-index: 10;
}

.ql-bubble .ql-tooltip-editor input[type=text] {
  left: 0;
  top: 0;
  color: #000!important;
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-label::before {
  position: absolute;
  z-index: 1;
  background: #fff;
}

.ql-bubble .ql-picker-options {
  background: #fff;
  border: 1px solid #f3f3f3;
}

.ql-bubble .ql-picker-options {
  background-color: #FFFFFF!important;
  border: 0px solid rgba(0, 0, 0, 0.25);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
  border-radius: 4px;
  padding: 15px 9px 9px 15px;
}

.ql-bubble .ql-color-picker .ql-picker-item {
  height: 30px!important;
  width: 30px!important;
  cursor: pointer;
  outline: none;
  float: left;
  border-radius: 4px;
  margin: 0px 6px 6px 0px;
}

.ql-bubble .ql-color-picker.ql-color .ql-picker-item[data-value="#ffffff"] {
  border: 1px solid #ededed;
}

.ql-bubble .ql-picker.ql-expanded.ql-color-picker .ql-picker-options {
  width: 420px!important;
  left: -355px!important;
  top: 30px!important;
}


.twitter-picker {
  width: 100%!important;
  margin-top: 10px;
  margin-bottom: 25px;
  overflow: hidden;
}

.twitter-picker div[title="#ffffff"] {
  border: 1px solid #ededed;
}

.ql-toolbar * {
  font-family: TitilliumWeb-Regular!important;
}

.ql-bubble .ql-tooltip {
  transition: none!important;
}

.ql-picker-input-color {
  width: 64px;
  height: 30px;
  border-radius: 4px;
  display: block;
  border: 1px solid #ccc;
  float: left;
  margin: 2px;
  padding-left: 5px;
}

.rdp-tooltip {
  width: 220px!important;
  font-size: 13px!important;
  line-height: 18px!important;
  padding: 10px 15px!important;
}

#default-poster {
  background-color: transparent!important;
}

.broken-img::after,
.broken-img-properties::after,
.broken-img-absolute::after {
  content: "\26A0" "  Arquivo indisponível, substitua-o ou remova o objeto.  " "\26A0";
  color: rgb(222, 17, 17);
  display: block;
  z-index: 2;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 50px;
  font-size: 20px;
  line-height: 40px;
  border: 2px solid red;
}

.broken-img-properties::after {
  font-size: 16px;
}

.broken-img-absolute::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ql-align-justify {
  text-align: justify;
};

.ql-align-center {
  text-align: center;
};

.ql-align-right {
  text-align: right;
}