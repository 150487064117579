.rdp-element-tabs-properties .rdp-composer-element-edit-body {
  padding: 0;
  top: 122px;
}

.element-tabs-properties-configurations {
  padding: 15px;
}

.element-tabs-properties-item {
  position: relative;
  padding: 15px;
  padding-bottom: 30px;
  padding-top: 30px;
  padding-left: 30px;
  border-bottom: 1px solid #ededed;
  transition: all 0.2s linear;
}

.element-tabs-properties-item-new {
  border: 1px solid #a3cb66;
  border-radius: 4px;
  color: #80b33a;
  padding: 12px 20px 12px 35px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 9px;
  cursor: pointer;
  position: relative;
  transition: 0.2s all linear;
  margin: 8px auto;
  display: block;
}

.element-tabs-properties-item-new:before {
  content: "";
  background-image: url("./icon-plus.svg");
  background-size: 15px;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 12px;
  top: 10px;
}

.element-tabs-properties-item-drag {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 30px;
  background-image: url("./icon-sort.svg");
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  cursor: move;
  transition: all 0.2s linear;
  display: none;
}

.element-tabs-properties-item:hover .element-tabs-properties-item-drag {
  opacity: 0.4;
}

.element-tabs-properties-item-remove {
  position: absolute;
  right: 10px;
  top: 24px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-image: url("./icon-remove.svg");
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: center;
}

.element-tabs-properties-item:hover {
  background: #f6f6f6;
}

.element-tabs-properties-item-label {
  width: 100%;
  display: block;
  color: #5c5c5c;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  margin-bottom: 5px;

  font-size: 16px;
  text-transform: unset;
  color: #000;
}

.element-tabs-properties-item-title {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ededed;
  line-height: 29px;
  font-size: 14px;
  margin-bottom: 20px;
  background: transparent;
}

.element-tabs-properties-item-text {
  font-size: 10px;
  margin-top: 10px;
  cursor: not-allowed;
}

.element-tabs-properties-item-text strong {
  font-weight: bold;
}

.element-tabs-properties-item-text strong {
  font-weight: bold;
}

.element-tabs-properties-item-text em {
  font-style: italic;
}

.element-tabs-properties-item-text u {
  text-decoration: underline;
}

.element-tabs-properties-item-text * {
  font-size: 14px!important;
  line-height: 16px;
}

.element-tabs-properties-item-upload-media {
  border-top: 1px solid #ededed;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

.element-tabs-properties-item-upload-image, .element-tabs-properties-item-upload-video {
  width: 140px;
  height: 40px;
  background: transparent;
  border: 0;
  font-size: 12px;
  color: #707070;
  position: relative;
  text-align: left;
  padding-left: 24px;
  cursor: pointer;
}

.element-tabs-properties-item-upload-image:before {
  content: "";
  background-image: url("./icon-image.svg");
  background-size: 100%;
  position: absolute;
  width: 15px;
  height: 15px;
  left: 0;
  top: 12px;
  opacity: 0.5;
}

.element-tabs-properties-item-upload-video:before {
  content: "";
  background-image: url("./icon-video.svg");
  background-size: 100%;
  position: absolute;
  width: 15px;
  height: 15px;
  left: 0;
  top: 12px;
  opacity: 0.5;
}

.element-tabs-properties-item-image-container {
  padding-top: 30px;
  padding-bottom: 20px;
  position: relative;
}

.element-tabs-properties-item-image {
  width: 80px;
  vertical-align: middle;
}

.element-tabs-properties-item-image-remove {
  vertical-align: middle;
  display: inline-block;
  width: calc(100% - 100px);
  text-align: right;
  font-size: 12px;
  color: #707070;
  cursor: pointer;
  text-decoration: underline;
  color: red;
}

.element-tabs-properties-item-image-remove:before {
  content: "";
  position: absolute;
  width: 1px;
  background: #e9e9e9;
  top: 30px;
  bottom: 20px;
  left: 95px;
}

.element-tabs-properties-item-video-container {
  padding-top: 30px;
  padding-bottom: 20px;
  position: relative;
}

.element-tabs-properties-item-video {
  width: 80px;
  vertical-align: middle;
}

.element-tabs-properties-item-video-remove {
  vertical-align: middle;
  display: inline-block;
  width: calc(100% - 100px);
  text-align: right;
  font-size: 12px;
  color: #707070;
  cursor: pointer;
  text-decoration: underline;
  color: red;
}

.element-tabs-properties-item-video-remove:before {
  content: "";
  position: absolute;
  width: 1px;
  background: #e9e9e9;
  top: 30px;
  bottom: 20px;
  left: 95px;
}

.mobile .element-tabs {
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and(max-width: 768px) {
  .element-tabs {
    padding-left: 20px;
    padding-right: 20px;
  }
}