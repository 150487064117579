
.rdp-composer-element-edit {
  position: fixed;
  z-index: 9999;
  /* background: rgba(245, 245, 245, 0.8); */
  background: rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rdp-composer-element-edit-modal {
  position: absolute;
  width: 550px;
  top: 0;
  bottom: 0;
  right: 0;
  background: #FFFFFF;
  z-index: 2;
  overflow: hidden;

  border-radius: 10px;
  right: 20px;
  bottom: 20px;
  top: 20px;

  /* width: 650px;
  height: 650px;
  top: 50%;
  margin-top: -325px;
  left: 50%;
  margin-left: -325px;
  border-radius: 8px; */
}

.rdp-composer-element-edit-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  z-index: 2;
}

.rdp-composer-element-edit-header {
  height: 70px;
}

.rdp-composer-element-edit-header-title {
  display: inline-block;
  vertical-align: middle;
  color: #1e1e1e;
  font-size: 18px;
  font-weight: bold;
  line-height: 70px;
  padding-left: 20px;
  text-transform: uppercase;
}

.rdp-composer-element-edit-header-template-picker {
  display: inline-block;
  vertical-align: middle;
  margin-left: 60px;
  position: relative;
}

.rdp-composer-element-edit-header-template-picker-btn {
  border: 1px solid #5d5e5d;
  border-radius: 100px;
  padding: 8px 30px 8px 15px;
  cursor: pointer;
  position: relative;
}

.rdp-composer-element-edit-header-template-picker-btn-label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  display: inherit;
  color: #5d5e5d;
}

.rdp-composer-element-edit-header-template-picker-btn-info {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 6px;
  right: 6px;
  background-image: url("./icon-info.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.rdp-composer-element-edit-header-template-picker-options {
  position: absolute;
  top: 35px;
  background: #FFF;
  box-shadow: 0px 0px 3px #6a6a6a;
  left: 0px;
  right: 0px;
  border-radius: 5px;
}

.rdp-composer-element-edit-header-template-picker-option {
  width: 100%;
  height: 35px;
  line-height: 35px;
  padding-left: 12px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  border-bottom: 1px solid #e7e7e7;
  color: #5d5e5d;
}

.rdp-composer-element-edit-header-close {
  width: 70px;
  height: 65px;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  background: #5d5d5d;
  cursor: pointer;
  -webkit-mask: url(./close.svg) no-repeat 50% 50%;
  mask: url(./close.svg) no-repeat 50% 50%;
  -webkit-mask-size: 30px;
  mask-size: 30px;
}

.rdp-composer-element-edit-tabs {
  height: 50px;
  box-shadow: 0 3px 5px 0px #e6e6e6;
}

.rdp-composer-element-edit-tabs-item {
  color: #5d5d5d;
  display: inline-block;
  line-height: 50px;
  padding: 0 15px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
}

.rdp-composer-element-edit-tabs-item.active {
  border-bottom: 3px solid #2d7b45;
  line-height: 47px;
}

.rdp-composer-element-edit-body {
  position: absolute;
  top: 120px;
  bottom: 60px;
  left: 0;
  right: 0;
  padding: 15px;
  overflow-x: hidden;
  overflow-y: auto;
}

.rdp-composer-element-divider {
  width: 100%;
  height: 1px;
  background: #e3e3e3;
  margin-bottom: 30px;
}

.rdp-composer-element-item-secondary-select {
  position: absolute;
  right: 2px;
  top: 0;
}

.rdp-composer-element-item-secondary-label {
  font-size: 12px;
  font-weight: bold;
  vertical-align: middle;
}

.rdp-composer-element-item-secondary-checkbox {
  vertical-align: middle;
}

.rdp-composer-element-edit-label {
  display: block;
  font-weight: bold;
}

.rdp-composer-element-edit-input, .rdp-composer-element-edit-select {
  width: 100%;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 25px;
  padding-left: 12px;
  background: #FFF;
  border: 1px solid #d7d7d7;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px; */
  /* border-radius: 4px; */
  position: relative;
}

.rdp-composer-element-edit-input-50 {
  display: inline-block;
  width: 50%;
}

.rdp-composer-element-edit-textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  margin-bottom: 25px;
  padding: 12px;
  border: 1px solid #d7d7d7;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px; */
  /* border-radius: 4px; */
  position: relative;
  resize: none;
}

.rdp-composer-element-edit-input::-webkit-outer-spin-button,
.rdp-composer-element-edit-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rdp-composer-element-edit-images {
  padding: 5px;
}

.rdp-composer-element-edit-images li {
  cursor: pointer;
  width: 137.5px;
  height: 137.5px;
  display: inline-block;
  background-position: 50% center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid #f5f5f5;
  margin: 5px;
}

.rdp-composer-element-edit-images li.active {
  border: 1px solid #7d7d7d;
  opacity: 0.5;
}

.rdp-composer-element-edit-videos {
  padding: 5px;
}

.rdp-composer-element-edit-videos li {
  cursor: pointer;
  width: 137.5px;
  height: 137.5px;
  display: inline-block;
  background-position: 50% center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid #f5f5f5;
  margin: 5px;
  position: relative;
}

.rdp-composer-element-edit-videos li .play {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-image: url(./video_play.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px;
  opacity: 0.4;
}

.rdp-composer-element-edit-videos li.active {
  border: 1px solid #7d7d7d;
  opacity: 0.5;
}

.rdp-composer-element-edit-footer {
  position: absolute;
  width: 100%;
  height: 60px;
  bottom: 0;
  text-align: right;
  padding: 9px 15px;
}

.rdp-composer-element-edit-submit {
  background: #2d7b45;
  color: #fff;
  border: 0;
  padding: 11px 26px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  min-width: 130px;
}

.rdp-composer-element-edit-template-new {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 0.9);
}

.rdp-composer-element-edit-template-new-modal {
  width: 400px;
  height: 260px;
  position: absolute;
  top: 50%;
  margin-top: -130px;
  left: 50%;
  margin-left: -200px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px #a9a9a9;
  background: #FFF;
  overflow: hidden;
}

.rdp-composer-element-edit-template-new-modal-header {
  position: absolute;
  height: 60px;
  left: 0px;
  right: 0px;
  top: 0px;
}

.rdp-composer-element-edit-template-new-modal-header-title {
  color: #1e1e1e;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding-left: 20px;
  line-height: 60px;
}

.rdp-composer-element-edit-template-new-modal-header-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 100px;
  background-color: #e7e7e7;
  background-image: url("./close.svg");
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center;
}

.rdp-composer-element-edit-template-new-modal-body {
  position: absolute;
  top: 60px;
  bottom: 60px;
  left: 0px;
  right: 0px;
  padding: 0px 20px;
}

.rdp-composer-element-edit-template-new-modal-body-subtitle {
  
}

.rdp-composer-element-edit-template-new-modal-body-input {
  width: 100%;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 25px;
  padding-left: 12px;
  background: #FFF;
  border: 0px solid rgba(0, 0, 0, 0.25);
  box-shadow: rgb(0, 0, 0, 0.25) 0px 0px 4px;
  border-radius: 4px;
  position: relative;
}

.rdp-composer-element-edit-template-new-modal-footer {
  position: absolute;
  height: 45px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  text-align: right;
}

.rdp-composer-element-edit-template-new-modal-cancel {
  background: #e7e7e7;
  color: #000;
  border: 0;
  padding: 11px 26px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  min-width: 130px;
  margin-right: 10px;
}

.rdp-composer-element-edit-template-new-modal-submit {
  background: #2d7b45;
  color: #fff;
  border: 0;
  padding: 11px 26px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  min-width: 130px;
  margin-right: 10px;
  opacity: 0.6;
  pointer-events: none;
}

.rdp-composer-element-edit-template-new-modal-submit.active {
  opacity: 1;
  pointer-events: inherit;
}

.rdp-composer-element-edit-template-new-modal-preloader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  background-image: url("./icon-preloader.gif");
  background-repeat: no-repeat;
  background-position: center;
}

.rdp-composer-element-edit-alert {
  color: #383d41;
  background-color: #e2e3e5;
  border: 1px solid #d6d8db;
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  border-radius: 10px;
  height: 60px;
  z-index: 1;
  padding: 20px 15px;
  font-weight: bold;
}

.rdp-composer-element-edit-alert.success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.rdp-composer-element-edit-alert.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.rdp-composer-element-edit-alert-close {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 50px;
  height: 58px;
  cursor: pointer;
  opacity: 0.5;
  background-image: url("./close.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.rdp-composer-element-edit-templates-list {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 0.9);
}

.rdp-composer-element-edit-templates-list-modal {
  width: 400px;
  position: absolute;
  top: 70px;
  bottom: 70px;
  left: 50%;
  margin-left: -200px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px #a9a9a9;
  background: #FFF;
  overflow: hidden;
}

.rdp-composer-element-edit-templates-list-modal-header {
  position: absolute;
  height: 60px;
  left: 0px;
  right: 0px;
  top: 0px;
}

.rdp-composer-element-edit-templates-list-modal-header-title {
  color: #1e1e1e;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding-left: 20px;
  line-height: 60px;
}

.rdp-composer-element-edit-templates-list-modal-header-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 100px;
  background-color: #e7e7e7;
  background-image: url("./close.svg");
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center;
}

.rdp-composer-element-edit-templates-list-modal-search {
  position: absolute;
  top: 65px;
  left: 0px;
  right: 0px;
  padding: 0px 20px;
}

.rdp-composer-element-edit-templates-list-modal-search-field {
  width: 100%;
  height: 40px;
  padding-left: 12px;
  background: #FFF;
  border: 0px solid rgba(0, 0, 0, 0.25);
  box-shadow: rgb(0 0 0 / 25%) 0px 0px 4px;
  border-radius: 4px;
  position: relative;
  background-image: url("./icon-search.svg");
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: calc(100% - 16px);
}

.rdp-composer-element-edit-templates-list-modal-items {
  position: absolute;
  top: 120px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 12px 20px;
}

.rdp-composer-element-edit-templates-list-modal-item {
  background: #fff;
  box-shadow: 0px 0px 10px rgba(199, 199, 199, 0.7);
  margin-bottom: 10px;
  padding: 20px 15px;
  position: relative;
}

.rdp-composer-element-edit-templates-list-modal-item-label {

}

.rdp-composer-element-edit-templates-list-modal-item-icon-preview {
  position: absolute;
  width: 30px;
  height: 30px;
  cursor: pointer;
  top: 12px;
  right: 40px;
  background-image: url("./icon-preview.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
}

.rdp-composer-element-edit-templates-list-modal-item-icon-select {
  position: absolute;
  width: 30px;
  height: 30px;
  cursor: pointer;
  top: 12px;
  right: 8px;
  background-image: url("./icon-select.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
}

.rdp-composer-element-edit-templates-list-modal-empty {
  position: absolute;
  top: 120px;
  bottom: 20px;
  left: 20px;
  right: 20px;
}

.rdp-composer-element-edit-templates-list-modal-empty-content {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -40px;
}

.rdp-composer-element-edit-templates-list-modal-empty-icon {
  width: 50px;
  height: 50px;
  background-image: url("./icon-template.svg");
  background-size: 100%;
  display: block;
  margin: auto;
  opacity: 0.55;
}

.rdp-composer-element-edit-templates-list-modal-empty-label {
  display: block;
  text-align: center;
  margin-top: 15px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px;
  color: #5d5e5d;
}

.rdp-composer-element-edit-templates-list-modal-preloader {
  position: absolute;
  top: 120px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  background-image: url("./icon-preloader.gif");
  background-position: center;
  background-repeat: no-repeat;
}

.rdp-composer-element-edit-templates-list-modal-applying-preloader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.95);
  background-image: url("./icon-preloader.gif");
  background-repeat: no-repeat;
  background-position: center;
}

