/*region element max-width*/
/*SM*/
.element-3d.max-w-sm .element-3d-area {
  max-width: 760px;
}
/*MD*/
.element-3d.max-w-md .element-3d-area {
  max-width: 1160px;
}
/*LG*/
.element-3d.max-w-lg .element-3d-area {
  max-width: 1600px;
}
/*FULL*/
.element-3d.max-w-full .element-3d-area {
  max-width: calc(100% - 60px);
}
/*endregion*/


.element-3d {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: block;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.element-3d:hover {
  outline: 1px dashed #CCC!important;
  outline-offset: -1px;
}

.element-3d-area {
  display: block;
  width: 100%;
  min-height: 400px;
  margin: auto;
}

#default-poster {
  background-color: transparent!important;
}