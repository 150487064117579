@import url('../../colors.css');

.rdp-composer-home-theme-dark,  
.rdp-composer-home-theme-dark .rdp-composer-home-compositions {
    background: var(--back);
  }

  .rdp-composer-home-theme-dark .rdp-composer-home-composition {
    background: var(--card);
    box-shadow: var(--shadow);
  }
  
  .rdp-composer-home-theme-dark  .rdp-composer-home-composition-author,  
  .rdp-composer-home-theme-dark .rdp-composer-home-composition-date,  
  .rdp-composer-home-theme-dark .rdp-composer-home-composition-title {
    color: var(--greyText);
  }
  
  .rdp-composer-home-theme-dark .rdp-composer-home-composition-author:before {
    background-image: url("./icon-author-dark-mode.svg");
  }
  
  .rdp-composer-home-theme-dark .rdp-composer-home-composition-date:before {
    background-image: url("./icon-date-dark-mode.svg");
  }
  
  .rdp-composer-home-theme-dark .rdp-composer-home-composition:hover .rdp-composer-home-composition-actions {
    opacity: 1;
    pointer-events: initial;
  }
  
  .rdp-composer-home-preloader {
    position: absolute;
    top: 135px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: #fafafa;
    background-image: url("./icon-preloader.gif");
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .rdp-composer-home-import-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
  }
  
  .rdp-composer-home-import-modal-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .rdp-composer-home-import-modal-window {
    width: 860px;
    height: 560px;
    background: #FFF;
    position: absolute;
    overflow: hidden;
    z-index: 1;
    top: 50%;
    margin-top: -280px;
    left: 50%;
    margin-left: -430px;
    border-radius: 4px;
  }
  
  .rdp-composer-home-import-modal-header {
    position: absolute;
    width: 100%;
    height: 60px;
    z-index: 100;
    box-shadow: 0px 0px 7px #ccc;
  }
  
  .rdp-composer-home-import-modal-header-title {
    font-weight: bold;
    line-height: 60px;
    padding-left: 20px;
  }
  
  .rdp-composer-home-import-modal-header-close {
    width: 60px;
    height: 60px;
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
    background-image: url("./icon-close.svg");
    background-position: center;
    background-size: 20px;
    background-repeat: no-repeat;
  }
  
  .rdp-composer-home-import-modal-body {
    position: absolute;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .rdp-composer-home-import-modal-body-sidebar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 260px;
    padding: 10px;
    box-shadow: 0px 0px 7px #ccc;
  }
  
  .rdp-composer-home-import-modal-body-sidebar-btn,
  .rdp-composer-home-import-modal-body-login-submit {
    background-color: #4d7aff;
    width: 100%;
    height: 44px;
    border: 0;
    border-radius: 3px;
    color: #FFF;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 11px;
    cursor: pointer;
    position: relative;
    padding-left: 54px;
  }
  
  .rdp-composer-home-import-modal-body-sidebar-btn:before,
  .rdp-composer-home-import-modal-body-login-submit:before {
    content: "";
    background-image: url("./icon-connect.svg");
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 54px;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    border-right: 1px solid #85a3ff;
  }
  
  .rdp-composer-home-import-modal-body-login {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 260px;
    padding: 10px;
    z-index: 10;
    background-color: #FFF;
    box-shadow: 0px 0px 7px #ccc;
  }
  
  .rdp-composer-home-import-modal-body-login-instructions {
    font-weight: bold;
    display: block;
    margin-bottom: 20px;
    line-height: 17px;
    font-size: 12px;
  }
  
  .rdp-composer-home-import-modal-body-login-label {
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
    margin-bottom: 5px;
  }
  
  .rdp-composer-home-import-modal-body-login-input {
    width: 100%;
    height: 40px;
    display: block;
    margin-bottom: 15px;
    border: 1px solid #d8d8d8;
    padding-left: 10px;
  }