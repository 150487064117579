.rdp-composer-element-audio {
  width: calc(100% - 50px);
  margin-top: 10px;
  margin-bottom: 25px;
  cursor: pointer;
}

.rdp-composer-element-audio-btn {
  width: 50px;
  height: 54px;
  display: inline-block;
  vertical-align: top;
  margin-top: 10px;
  border-radius: 85px;
  background-image: url("./icon-edit.svg");
  background-position: center;
  background-size: 22px;
  background-repeat: no-repeat;
  cursor: pointer;
}