.rdp-component-popup {
  position: fixed;
  width: 400px;
  height: 65px;
  background: #2d7b45;
  z-index: 1000;
  bottom: 10px;
  right: 10px;
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  padding: 18px;
  padding-left: 65px;
  box-shadow: 0px 0px 15px rgb(34 34 34 / 50%);
  background-image: url("./icon-download.svg");
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: 20px;
}