@import url('../../colors.css');

.rdp-component-remove-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background-color: rgba(112, 112, 112, 0.7);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 180px;
}

.rdp-component-remove-modal-popup {
  width: 550px;
  height: 310px;
  border-radius: 4px;
  background: #FFF;
  text-align: center;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50%;
  margin-top: -155px;
  left: 50%;
  margin-left: -275px;
}

.rdp-component-remove-modal-theme-dark .rdp-component-remove-modal-popup {
  background: var(--card);
}
.rdp-component-remove-modal-theme-dark .rdp-component-remove-modal-close {
  background-image: url("./icon-close-dark.svg");
}

.rdp-component-remove-modal-close {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  cursor: pointer;
  background: url("./icon-close.svg");
  background-position: center;
  background-size: 24px;
  background-repeat: no-repeat;
}

.rdp-component-remove-modal-icon {
  display: block;
  width: 100px;
  height: 100px;
  margin: auto;
  margin-top: 20px;
  background-image: url("./icon-remove.png");
  background-size: 100%;
}

.rdp-component-remove-modal-theme-dark .rdp-component-remove-modal-icon {
  background-image: url("./icon-remove-dark.svg");

}

.rdp-component-remove-modal-title {
  display: block;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: #5c646e;
  margin-top: 20px;
}

.rdp-component-remove-modal-message {
  color: #a5aab2;
  display: block;
  text-align: center;
  margin-top: 25px;
  font-size: 18px;
  line-height: 20px;
}

.rdp-component-remove-modal-input {
  border-radius: 4px;
  border: 1px solid #CCC;
  height: 37px;
  width: 320px;
  display: block;
  margin: auto;
  margin-top: 20px;
  padding-left: 12px;
  background: #fafafa;
}

.rdp-component-remove-modal-submit {
  border: 1px solid #fe0000;
  border-radius: 4px;
  color: #fe0000;
  padding: 12px 20px 12px 35px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 9px;
  margin-top: 30px;
  cursor: pointer;
  position: relative;
  transition: 0.2s all linear;
}

.rdp-component-remove-modal-theme-dark .rdp-component-remove-modal-submit {
  border: none;
  color: #fff;
  background: var(--primary);
}
.rdp-component-remove-modal-theme-dark .rdp-component-remove-modal-submit:before  {
  background-image: url("./icon-trash.svg");

}
.rdp-component-remove-modal-submit:before {
  content: "";
  background-image: url("./icon-remove.svg");
  background-size: 15px;
  width: 15px;
  height: 18px;
  position: absolute;
  left: 12px;
  top: 8px;
}

.rdp-component-remove-modal-submit.inactive {
  opacity: 0.2;
  cursor: not-allowed;
  pointer-events: none;
}