/*region element max-width*/
/*SM*/
.element-accordion.max-w-sm .element-accordion-items  {
  max-width: 760px;
}
/*MD*/
.element-accordion.max-w-md .element-accordion-items  {
  max-width: 1160px;
}
/*LG*/
.element-accordion.max-w-lg .element-accordion-items  {
  max-width: 1600px;
}
/*FULL*/
.element-accordion.max-w-full .element-accordion-items  {
  max-width: calc(100% - 60px);
}
/*endregion*/

.element-accordion {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;
}

.element-accordion-items {
  width: 100%;
  display: block;
  margin: auto;
  background: #FFFFFF;
  border-top: 1px solid #ddd;
  box-shadow: 0 0.4rem 1.2rem 0.2rem rgba(0,0,0,.05);
}

.element-accordion-item {
  height: 88px;
  border: 1px solid #ddd;
  border-top: 0;
  overflow: hidden;
  position: relative;
  transition: all 0.2s linear;
  background-image: url("./icon-plus.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 34px;
}

.element-accordion-item:hover {
  border: 1px solid #ddd;
  border-top: 0;
}

.element-accordion-item.expanded {
  height: auto;
  background-image: url("./icon-minus.svg");
}

.element-accordion-item-border {
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 5px;
  width: 3px;
  z-index: 1;
  display: none;
}

.element-accordion-item.expanded .element-accordion-item-border {
  display: block;
}

.element-accordion-item-title {
  cursor: pointer;
  display: block;
  font-size: 18px;
  font-weight: bold;
  padding: 35px 30px;
}

.element-accordion-item-text {
  padding: 30px;
  padding-top: 0;
}

.element-accordion-item-text .ql-editor {
  padding: 0;
  line-height: 1.42;
}

.element-accordion-item-text .ql-editor strong {
  font-weight: bold;
}

.element-accordion-item-text .ql-editor strong {
  font-weight: bold;
}

.element-accordion-item-text .ql-editor em {
  font-style: italic;
}

.element-accordion-item-text .ql-editor u {
  text-decoration: underline;
}

.element-accordion-item-image {
  width: calc(100% - 60px);
  margin: 30px;
  margin-top: 0;
}

.element-accordion-item-video {
  width: calc(100% - 60px);
  margin: 30px;
  margin-top: 0;
}

.mobile .element-accordion {
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 768px) {
  .element-accordion {
    padding-left: 20px;
    padding-right: 20px;
  }
}