.rdp-composer-subheader {
  height: 55px;
  position: fixed;
  left: 0;
  right: 0;
  top: 80px;
  z-index: 1;
  border-bottom: 1px solid #eee;
}

.rdp-composer-subheader-new {
  border: 1px solid #a3cb66;
  border-radius: 4px;
  color: #80b33a;
  padding: 12px 20px 12px 35px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 9px;
  margin: 8px 18px;
  cursor: pointer;
  position: relative;
  transition: 0.2s all linear;
}

.rdp-composer-subheader-new::before {
  content: "";
  background-image: url("./icon-plus.svg");
  background-size: 15px;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 12px;
  top: 10px;
}

.rdp-composer-subheader-new:hover {
  opacity: 0.5;
}