.rdp-composer-preview-background {
  position: absolute;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  background: #444444;
}

.rdp-composer-preview-device {
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  /* border: 20px solid #3c3c3c; */
  overflow: hidden;
  /* border-radius: 3px; */
  transition: all 0.2s ease-in-out;
}

.rdp-composer-preview-device.mobile {
  width: 360px;
  height: 608px;
  margin-top: -304px;
  margin-left: -180px;
}

.rdp-composer-preview-device.tablet {
  width: 768px;
  height: 100%;
  margin-top: 0;
  margin-left: -384px;
  top: 0;
}

.rdp-composer-preview-device.desktop {
  width: 100%;
  height: 100%;
  margin-top: 0;
  margin-left: 0;
  top: 0;
  left: 0;
}

.rdp-composer-preview .rdp-composer-structure {
  top: 0;
}

.mobile .element-multimedia-2 video {
  width: 100%;
}

.tablet .element-multimedia-2 video {
  width: 100%;
}

.mobile .element-image-4 .container div {
  font-size: 18px;
  line-height: 25px;
}

.tablet .element-gallery-2 .container .second-image img {
  width: 100%;
  height: auto;
}

.tablet .rdp-element-text {
  margin-left: 20px;
  margin-right: 20px;
}

.mobile .element-gallery-2 .container .first-image img, .mobile .element-gallery-2 .container .second-image img,
.tablet .element-gallery-2 .container .first-image img, .tablet .element-gallery-2 .container .second-image img {
  width: 100%;
  height: auto;
}

.mobile .element-gallery-2 .container .first-image,
.mobile .element-gallery-2 .container .second-image {
  width: 100%;
  height: auto;
}

.mobile .element-gallery-2 .container .second-image {
  margin-left: 0;
  margin-top: 30px;
}

/* .rdp-composer-preview .rdp-composer-structure [class^=element-],
.rdp-composer-preview .rdp-composer-structure [class*="element-"],
.rdp-composer-preview .rdp-composer-structure *:hover { */
.rdp-composer-preview .rdp-composer-structure > div,
.rdp-composer-preview .rdp-composer-structure *:hover {
  /* border: 0; */
}

/* .rdp-composer-preview .editor span,
.rdp-composer-preview .editor strong {
  line-height: 120%;
} */

.rdp-composer-preview .ql-align-center {
  text-align: center;
}

.rdp-composer-preview .editor strong {
  font-weight: bold;
}

.rdp-composer-preview .mobile .editor * {
  /* font-size: max(40px)!important; */
}

.mobile .element-hotspots-1 .hotspots-1-spot {
  transform: scale(0.3);
}

.tablet .element-hotspots-1 .hotspots-1-spot.expanded:focus::before {
  left: 55px;
}

.tablet .element-hotspots-1 .hotspots-1-spot.expanded.flipped:focus::before {
  left: -36px;
}

.tablet .element-hotspots-1 .hotspots-1-spot {
  transform: scale(0.7);
}

.mobile .element-hotspots-1 .hotspot-tooltip {
  top: 50%;
  left: 50%;
  position: fixed;
  z-index: 9999;
  width: 360px;
  height: 608px;
  max-height: 100%;
  border-radius: 0;
  margin-top: -265px;
  margin-left: -180px;
  max-width: 100%;
}

.mobile .element-hotspots-1 .hotspot-tooltip .hotspot-tooltip-navigation-button-prev,
.mobile .element-hotspots-1 .hotspot-tooltip .hotspot-tooltip-navigation-button-next {
  display: none;
}

.mobile .element-hotspots-1 .hotspot-tooltip .hotspot-tooltip-navigation-button-close {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.mobile .element-steps-1 .change-step-1-button {
  display: none;
}

.mobile .element-quote-6-controls {
  display: none;
}

.mobile .element-quote-6-slides {
  pointer-events: auto;
}

.mobile .element-quote-6-slide,
.mobile .element-quote-6-carrousel {
  width: 360px
}

.mobile .element-gallery-1-controls-left,
.mobile .element-gallery-1-controls-right {
  opacity: 0;
  pointer-events: none;
}

.mobile .element-gallery-1-slides {
  pointer-events: auto;
}

.mobile .element-gallery-1-slide,
.mobile .element-gallery-1-carrousel {
  width: 360px
}

.mobile .element-gallery-1-image-container {
  height: auto;
  box-shadow: none
}

.mobile .element-snippet-1 .element-snippet-1__snippet-item {
  min-width: auto;
  width: 100%;
}
.mobile .element-quiz-4 .quiz-match-line__match,
.mobile .element-quiz-4 .quiz-match-line__choice {
  transform: scale(0.7);
}

.mobile .element-quiz-4 .quiz-match-line__match-line {
  grid-template-columns: 10% 80%;
  grid-gap: 40%;
}

.mobile .element-quiz-4 .quiz-match-line__match-line-connected {
  grid-template-columns: 10% 80%;
  grid-gap: 35%;
}
.mobile .element-button-1__button-item {
  justify-content: center;
  padding: 0 10px;
}

.mobile .element-button-1__button {
  margin: 0 10px;
}