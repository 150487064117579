/*region element max-width*/
/*SM*/
.element-iframe.max-w-sm iframe  {
  max-width: 760px;
}
/*MD*/
.element-iframe.max-w-md iframe  {
  max-width: 1160px;
}
/*LG*/
.element-iframe.max-w-lg iframe  {
  max-width: 1600px;
}
/*FULL*/
.element-iframe.max-w-full iframe  {
  max-width: calc(100% - 60px);
}
/*endregion*/

.element-iframe {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: block;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.element-iframe:hover {
  outline: 1px dashed #CCC!important;
  outline-offset: -1px;
}

.element-iframe iframe {
  display: block;
  width: 100%;
  margin: auto;
  background: transparent;
}