.rdp-composer-header {
  height: 80px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  border-bottom: 1px solid #eee;
  z-index: 10000;
}

.rdp-composer-header * {
  font-family: Prompt-Regular;
}

.rdp-composer-header .rdp-composer-header-active-project-name,
.rdp-composer-header .rdp-composer-header-active-user-name {
  font-family: Prompt-Bold;
}

.rdp-composer-header-logo {
  width: 130px;
  height: 44px;
  background-image: url("./logo.png");
  background-size: 130px;
  margin-left: 20px;
  margin-top: 18px;
}

.rdp-composer-header-avatar {
  width: 30px;
  height: 30px;
  background-image: url("./avatar.png");
  background-repeat: no-repeat;
  background-size: 30px;
  position: absolute;
  top: 25px;
  right: 20px;
  border-radius: 100px;
  cursor: pointer;
}

.rdp-composer-header-h3 {
  position: absolute;
  top: 0;
  bottom: 0;
  line-height: 80px;
  padding: 0px 20px;
  color: #636363;
  font-size: 18px;
}

.rdp-composer-header-user-dropdown-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.rdp-composer-header-user-dropdown-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rdp-composer-header-user-dropdown-popup {
  position: fixed;
  width: 180px;
  /* height: 115px; */
  height: 55px;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  top: 65px;
  right: 10px;
  z-index: 1;
  overflow: hidden;
}

.rdp-composer-header-user-dropdown-user {
  padding: 15px;
  border-bottom: 2px solid #efefef;
}

.rdp-composer-header-user-dropdown-user-avatar {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-image: url("./avatar.png");
  background-size: 100%;
  vertical-align: middle;
  border-radius: 100px;
}

.rdp-composer-header-user-dropdown-user-name {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
}

.rdp-composer-header-user-dropdown-signout {
  display: block;
  height: 60px;
  line-height: 55px;
  padding-left: 16px;
  font-size: 10px;
  color: #747474;
  cursor: pointer;
  transition: 0.2s all linear;
  text-transform: uppercase;
}

.rdp-composer-header-user-dropdown-signout:hover {
  background: #efefef;
  color: #222;
}

/* .rdp-composer-header-active-project::before {
  content: "";
  background-image: url("./icon-down.svg");
  background-size: 100%;
  position: absolute;
  width: 11px;
  height: 11px;
  right: 0;
  top: 15px;
} */



.rdp-composer-header-active-project {
  position: absolute;
  top: 0;
  right: 180px;
  height: 79px;
  background: #ffffff;
  cursor: pointer;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 30px;
  border-left: 1px solid #eee;
}

.rdp-composer-header-active-project-label {
  position: absolute;
  color: #999;
  font-size: 9px;
  text-transform: uppercase;
}

.rdp-composer-header-active-project-name {
  color: #000;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  max-width: 120px;
  margin-top: 13px;
  display: inline-block;
}

.rdp-composer-header-active-project-name::after {
  content: "";
  background-image: url("./icon-down.svg");
  background-size: 100%;
  width: 11px;
  height: 11px;
  display: inline-block;
  margin-left: 4px;
  background-position: 0px 1px;
}

.rdp-composer-header-active-user {
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  height: 79px;
  z-index: 1;
  width: 180px;
  cursor: pointer;
  border-left: 1px solid #eee;
}

.rdp-composer-header-active-user-avatar {
  width: 36px;
  height: 36px;
  background-image: url("./avatar.png");
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: 36px;
  position: absolute;
  top: 22px;
  left: 16px;
  border-radius: 100px;
  cursor: pointer;
}

.rdp-composer-header-active-user-hello {
  position: absolute;
  left: 60px;
  top: 30px;
  color: #999;
  font-size: 9px;
  text-transform: uppercase;
}

.rdp-composer-header-active-user-name {
  position: absolute;
  left: 60px;
  top: 42px;
  color: #000;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  max-width: 120px;
}

.rdp-composer-header-active-user-name::after {
  content: "";
  background-image: url("./icon-down.svg");
  background-size: 100%;
  width: 11px;
  height: 11px;
  display: inline-block;
  margin-left: 4px;
  background-position: 0px 1px;
}



.rdp-composer-header-projects-dropdown-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.rdp-composer-header-projects-dropdown-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rdp-composer-header-projects-dropdown-popup {
  position: fixed;
  width: 180px;
  height: auto;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  top: 65px;
  right: 190px;
  z-index: 1;
  overflow: hidden;
  padding: 5px 0px;
}

.rdp-composer-header-projects-dropdown-popup-name {
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  font-size: 10px;
  color: #727272;
  border-bottom: 1px solid #f6f6f6;
  cursor: pointer;
  transition: 0.2s all linear;
  text-transform: uppercase;
}

.rdp-composer-header-projects-dropdown-popup-name.active {
  pointer-events: none;
  background-image: url("./icon-check.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
}

.rdp-composer-header-projects-dropdown-popup-name:last-child {
  border-bottom: 0;
}

.rdp-composer-header-projects-dropdown-popup-name:hover {
  background: #efefef;
}