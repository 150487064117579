.ql-container.ql-bubble:not(.ql-disabled) a::before, .ql-container.ql-bubble:not(.ql-disabled) a::after {
    display: none !important;
}

.ql-container.ql-bubble:not(.ql-disabled) a {
    cursor: pointer;
    white-space: normal;
}

.ql-tooltip-editor .quill-clink-remove {
    width: 20px;
    display: flex;
    float: right;
    height: 20px;
    font-size: 20px;
    background-image: url('./icon-trash.svg');
    background-size: cover;
    position: absolute !important;
    top: 30px !important;
    right: 30px !important;
    margin-top: 0 !important;
}

.quill-properties .ql-tooltip-editor .quill-clink-remove {
    margin-top: 4px !important;
    top: 10px !important;
    right: 10px !important;
}

.ql-lineheight .select-lineheight {
    position: absolute;
    top: 100%;
    right: 100%;
    margin-right: -30px;
    border: none;
    box-shadow: 0 0 3px #ccc;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    align-items: start;
    font-size: 1rem;
} 

.ql-lineheight .select-lineheight span {
    padding: 5px 30px;
}

.ql-lineheight .select-lineheight span:hover {
    cursor: pointer;
    font-weight: bold;
}

.ql-bubble .ql-picker.ql-lineheight{
    width: 58px;
  }
  
  .ql-bubble .ql-picker.ql-lineheight .ql-picker-item[data-value='1.5']::before {content: '1.0';}
  .ql-bubble .ql-picker.ql-lineheight .ql-picker-label[data-value='1.5']::before {content: '1.0' !important;}
  .ql-bubble .ql-picker.ql-lineheight .ql-picker-item[data-value='1.7']::before {content: '1.5';}
  .ql-bubble .ql-picker.ql-lineheight .ql-picker-label[data-value='1.7']::before {content: '1.5' !important;}
  .ql-bubble .ql-picker.ql-lineheight .ql-picker-item[data-value='2.1']::before {content: '2.0';}
  .ql-bubble .ql-picker.ql-lineheight .ql-picker-label[data-value='2.1']::before {content: '2.0' !important;}
  .ql-bubble .ql-picker.ql-lineheight .ql-picker-item[data-value='2.5']::before {content: '2.5';}
  .ql-bubble .ql-picker.ql-lineheight .ql-picker-label[data-value='2.5']::before {content: '2.5' !important;}
  .ql-bubble .ql-picker.ql-lineheight .ql-picker-item[data-value='3.1']::before {content: '3.0';}
  .ql-bubble .ql-picker.ql-lineheight .ql-picker-label[data-value='3.1']::before {content: '3.0' !important;}