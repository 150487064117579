/*region element max-width*/
/*SM*/
.element-tabs.max-w-sm .element-tabs-container {
  max-width: 760px;
}
/*MD*/
.element-tabs.max-w-md .element-tabs-container {
  max-width: 1160px;
}
/*LG*/
.element-tabs.max-w-lg .element-tabs-container {
  max-width: 1600px;
}
/*FULL*/
.element-tabs.max-w-full .element-tabs-container {
  max-width: calc(100% - 60px);
}
/*endregion*/

.element-tabs {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;
}

.element-tabs-container {
  width: 100%;
  margin: auto;
  position: relative;
  background: #FFFFFF;
  overflow: hidden;
  border: 1px solid #ddd;
  box-shadow: 0 0.4rem 1.2rem 0.2rem rgba(0,0,0,.05);
}

.element-tabs-items {
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow-y: auto;
  position: absolute;
  z-index: 2;
  text-align: center;
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); */
}

.element-tabs-items::-webkit-scrollbar {
  height: 0.3em;
}
.element-tabs-items::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 4px;
}
.element-tabs-items::-webkit-scrollbar-thumb {
  background-color: #80808069;
  border-radius: 4px;
}

.element-tabs-item {
  display: inline-block;
  width: auto;
  height: 88px;
  position: relative;
  transition: all 0.2s linear;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 34px;
}

.element-tabs-item.active {
  border-bottom: 0;
  border-bottom: 3px solid #a5a5a5;
}

.element-tabs-item.active .element-tabs-item-title {
  color: #a5a5a5;
}

.element-tabs-item.active:after {
  content: "";
}

.element-tabs-item:last-child {
  /* border-right: 0; */
}

.element-tabs-item:hover {
  /* border: 1px solid #ddd; */
  /* border-top: 0; */
}

.element-tabs-item-border {
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 5px;
  width: 3px;
  z-index: 1;
  display: none;
}

.element-tabs-item.expanded .element-tabs-item-border {
  display: block;
}

.element-tabs-item-title {
  cursor: pointer;
  display: block;
  color: #313537;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  padding: 37px 30px;
}

.element-tabs-item-text {
  padding: 30px;
  padding-top: 0;
}

.element-tabs-content .ql-editor {
  padding: 0;
  line-height: 1.42;
}

.element-tabs-content .ql-editor strong {
  font-weight: bold;
}

.element-tabs-content .ql-editor strong {
  font-weight: bold;
}

.element-tabs-content .ql-editor em {
  font-style: italic;
}

.element-tabs-content .ql-editor u {
  text-decoration: underline;
}

.element-tabs-item-image {
  width: 100%;
  margin-top: 30px;
}

.element-tabs-item-video {
  width: 100%;
  margin-top: 30px;
}

.mobile .element-accordion {
  padding-left: 20px;
  padding-right: 20px;
}

.element-tabs-content {
  padding: 30px;
  margin-top: 86px;
}

@media screen and (max-width: 768px) {
  .element-accordion {
    padding-left: 20px;
    padding-right: 20px;
  }
}
