.element-forum-1 {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;
}

.element-forum-content {
  max-width: 760px;
  display: block;
  margin: auto;
}


.element-forum-content-message {
  background-color: #e1f1eb;
  /* border: 1px solid rgba(229, 229, 229, 0.92); */
  padding: 20px;
  border-radius: 10px;
}

.element-forum-content-message-child {
  background-color: #f1f1f1;
  /* border: 1px solid #ededed; */
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 15px;
}

.element-forum-content-message-author {
  font-size: 12px;
  margin-bottom: 15px;
  color: #A8A8A8;
  display: block;
}

.element-forum-content-message-message {
  font-size: 14px;
  line-height: 18px;
  color: #727272;
  display: block;
}

.element-forum-content-message-actions {
  display: block;
  text-align: right;
  margin-top: 10px;
}

.element-forum-content-message-action {
  text-transform: uppercase;
  color: #00643e;
  font-size: 10px;
  font-weight: bold;
  display: inline-block;
  margin-left: 10px;
  cursor: not-allowed;
}

.element-forum-content-message-children {
  padding-top: 15px;
  padding-left: 40px;
}