@import url('../../colors.css');

.rdp-component-content-search {
  position: absolute;
  right: 0;
  top: 0;
  width: 280px;
}

.rdp-component-content-search-submit {
  content: "";
  position: absolute;
  top: 8px;
  right: 20px;
  width: 35px;
  height: 36px;
  background-image: url("./icon-search.svg");
  background-size: 15px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.rdp-component-content-search-theme-dark .react-autosuggest__container input {
  color: #fff !important;
}
.rdp-component-content-search-theme-dark .react-autosuggest__container {
  border: 1px solid #cccccc6c;
}
.react-autosuggest__container {
  border-radius: 4px;
  border: 1px solid #CCC;
  height: 37px;
  position: absolute;
  right: 20px;
  top: 8px;
  width: 280px;
}

/* .react-autosuggest__container::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  background-image: url("./icon-search.svg");
  background-size: 15px;
  background-position: center;
  background-repeat: no-repeat;
} */

.react-autosuggest__container input {
  width: 100%;
  height: 100%;
  border: 0;
  background: transparent;
  padding-left: 10px;
  font-size: 9px;
  text-transform: uppercase;
}

.react-autosuggest__container #react-autowhatever-1 {
  width: 100%;
}

.react-autosuggest__container #react-autowhatever-1 ul {
  background: #FFFFFF;
  border-radius: 4px;
  margin-top: 4px;
  width: calc(100% + 2px);
  margin-left: -1px;
}

.react-autosuggest__container #react-autowhatever-1 ul li {
  width: 100%;
  height: 35px;
  line-height: 35px;
  padding-left: 10px;
  border: 1px solid #ebebeb;
  border-bottom: 0;
  cursor: pointer;
}

.react-autosuggest__container #react-autowhatever-1 ul li:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.react-autosuggest__container #react-autowhatever-1 ul li:last-child {
  border-bottom: 1px solid #ebebeb;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.rdp-component-content-search-theme-dark .react-autosuggest__container input {
  color: #fff !important;
}
.rdp-component-content-search-theme-dark .react-autosuggest__container {
  border: 1px solid #cccccc6c;
}
.rdp-component-content-search-theme-dark #react-autowhatever-1 ul {
  background: var(--greyBack);
}
.rdp-component-content-search-theme-dark  .react-autosuggest__container #react-autowhatever-1 ul li {
  border: 1px solid #ebebeb15;
  border-bottom: 0;
  color: #fff;
}