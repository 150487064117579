/*region element max-width*/
/*SM*/
.element-divider-numbered.max-w-sm .element-divider-numbered-content  {
  max-width: 760px;
}
/*MD*/
.element-divider-numbered.max-w-md .element-divider-numbered-content  {
  max-width: 1160px;
}
/*LG*/
.element-divider-numbered.max-w-lg .element-divider-numbered-content  {
  max-width: 1600px;
}
/*FULL*/
.element-divider-numbered.max-w-full .element-divider-numbered-content  {
  max-width: calc(100% - 60px);
}
/*endregion*/

.element-divider-numbered {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: block;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.element-divider-numbered:hover {
  outline: 1px dashed #CCC!important;
  outline-offset: -1px;
}

.element-divider-numbered-content {
  width: 100%;
  max-width: 760px;
  display: block;
  margin: auto;
  position: relative;
  height: 50px;
  padding-top: 25px;
}

.element-divider-numbered-line {
  width: 100%;
  height: 1px;
  background-color: #e6e6e6;
}

.element-divider-numbered-circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 100px;
  text-align: center;
  vertical-align: top;
  position: absolute;
  left: 50%;
  margin-left: -25px;
  top: 0px;
  font-weight: bold;
}