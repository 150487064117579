@import url('../../colors.css');

.rdp-composer-sidebar {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  /* overflow-x: hidden;
  overflow-y: auto; */
  overflow: hidden;
  text-align: center;
  border-bottom: 1px solid #f3f3f3;
  color: #000;
  background-color: #FFFFFF;
}

.rdp-composer-sidebar-items {
  position: absolute;
  left: 150px;
  right: 150px;
  top: 0;
  bottom: 0;
}

.rdp-composer-sidebar-back-embed {
  position: absolute;
  left: 10px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 80px;
  color: #764ab0;
  cursor: pointer;
}

.rdp-composer-sidebar-theme-dark, .rdp-composer-sidebar-theme-dark .rdp-composer-sidebar-back-embed {
  color: #fff;
  border-bottom: 1px dashed #cccccc46;
}
.rdp-composer-sidebar-back-embed::before {
  content: "";
  width: 30px;
  height: 80px;
  display: inline-block;
  vertical-align: top;
  background-image: url("./left-purple.svg");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center 33px;
}

.rdp-composer-sidebar-theme-dark .rdp-composer-sidebar-back-embed::before {
  background-image: url("./left-dark.svg");
}


.rdp-composer-sidebar-back {
  position: absolute;
  left: 10px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 80px;
  color: #2d7b45;
  cursor: pointer;
}

.rdp-composer-sidebar-back::before {
  content: "";
  width: 30px;
  height: 80px;
  display: inline-block;
  vertical-align: top;
  background-image: url("./left.svg");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: center 33px;
}

.rdp-composer-sidebar-logo {
  position: absolute;
  background: url("./logo.png");
  left: 18px;
  top: 18px;
  width: 125px;
  height: 42px;
  background-size: 125px;
}

.rdp-composer-sidebar-subitems {
  position: fixed;
  z-index: 1211;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  padding-bottom: 0px;
  background: #ececec;
  overflow-x: auto;
  overflow-y: hidden;
}

.rdp-composer-sidebar-subitems-theme-dark, 
.rdp-composer-sidebar-subitems-theme-dark .rdp-composer-sidebar-subitem-message
{
  background: var(--card);
  color: var(--greyText);
}

.rdp-composer-sidebar-preview {
  background: #2d7b45;
  color: #fff;
  border: 0;
  padding: 8px 30px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 30px;
  position: absolute;
  right: 20px;
  top: 24px;
  cursor: pointer;
}

.rdp-composer-sidebar-overlay {
  position: fixed;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
}

.rdp-composer-sidebar-menu-btn {
  width: 80px;
  height: 80px;
  position: absolute;
  right: 0;
  cursor: pointer;
  background: url("./icon-menu.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  border-left: 2px solid #f6f6f6;
  transition: 0.2s all linear;
}

.rdp-composer-sidebar-theme-dark .rdp-composer-sidebar-menu-btn {
  background-image: url("./icon-menu-dark.svg") !important;
  border-left: 1px dashed #f6f6f63a;
}

.rdp-composer-sidebar-theme-dark .rdp-composer-sidebar-menu-btn:hover {
  background-color: rgb(46, 46, 53);
}

.rdp-composer-sidebar-menu-btn:hover {
  background-color: #efefef;
}

.rdp-composer-sidebar-menu-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.rdp-composer-sidebar-menu-container-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rdp-composer-sidebar-menu-container-popup {
  position: fixed;
  width: 220px;
  height: auto;
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  top: 65px;
  right: 10px;
  z-index: 1;
  overflow: hidden;
  padding: 5px 0px;
}

.rdp-composer-sidebar-theme-dark .rdp-composer-sidebar-menu-container-popup {
  background: var(--card);
}
.rdp-composer-sidebar-theme-dark .rdp-composer-sidebar-menu-container-popup-item {
  color: var(--greyText);
  border-bottom: 1px solid alpha(var(--grey), 0.5);
}

.rdp-composer-sidebar-menu-container-popup-item {
  height: 40px;
  line-height: 40px;
  padding-left: 50px;
  font-size: 15px;
  color: #3b3b3b;
  border-bottom: 1px solid #f6f6f6;
  cursor: pointer;
  transition: 0.2s all linear;
  text-align: left;
}

.rdp-composer-sidebar-menu-container-popup-item:last-child {
  border-bottom: 0;
}

.rdp-composer-sidebar-menu-container-popup-item:hover {
  background: #efefef60;
}

.rdp-composer-sidebar-menu-container-popup-item.save {
  background-image: url("./icon-save.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 14px 10px;
}

.rdp-composer-sidebar-menu-container-popup-item.metadata {
  background-image: url("./icon-edit.svg");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: 16px 10px;
}

.rdp-composer-sidebar-menu-container-popup-item.history {
  background-image: url("./icon-history.svg");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: 16px 10px;
}

.rdp-composer-sidebar-menu-container-popup-item.preview {
  background-image: url("./icon-preview.svg");
  background-size: 19px;
  background-repeat: no-repeat;
  background-position: 15px 10px;
}

.rdp-composer-sidebar-menu-container-popup-item.zip {
  background-image: url("./icon-zip.svg");
  background-size: 19px;
  background-repeat: no-repeat;
  background-position: 15px 10px;
}

.rdp-composer-sidebar-menu-container-popup-item.zip-error {
  background-image: url("./icon-warning.svg");
  background-size: 19px;
  background-repeat: no-repeat;
  background-position: 15px 10px;
  /* opacity: 0.6; */
}

.rdp-composer-sidebar-menu-container-popup-item.zip-error::after {

}

.rdp-composer-sidebar-menu-container-popup-item.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.rdp-composer-sidebar-theme-dark .rdp-composer-sidebar-menu-container-popup-item.save {
  background-image: url("./icon-save-dark.svg");
}

.rdp-composer-sidebar-theme-dark .rdp-composer-sidebar-menu-container-popup-item.metadata {
  background-image: url("./icon-edit-dark.svg");
}

.rdp-composer-sidebar-theme-dark .rdp-composer-sidebar-menu-container-popup-item.history {
  background-image: url("./icon-history-dark.svg");
}

.rdp-composer-sidebar-theme-dark .rdp-composer-sidebar-menu-container-popup-item.preview {
  background-image: url("./icon-preview-dark.svg");
}
.rdp-composer-sidebar-theme-dark .zip {
  background-image: url("./icon-zip-dark.svg");
  background-size: 30px;
  background-position-y: 5px;
  background-position-x: 9px;
  background-repeat: no-repeat;
}
