.rdp-composer-structure {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

/* .rdp-composer-structure * {
  font-family: Merriweather-Regular;
} */
.react-draggable {
  z-index: 1211;
}
.rdp-composer-composer-preloader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.75);
  background-image: url("./icon-preloader.gif");
  background-position: center;
  background-repeat: no-repeat;
}

.rdp-composer-google-fonts {
  position: fixed;
  z-index: 9999;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(255, 255, 255, 0.7);
}

.rdp-composer-google-fonts ul {
  position: absolute;
  top: 100px;
  bottom: 100px;
  left: 100px;
  right: 100px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 19px #ccc;
}

.rdp-composer-google-fonts ul li {
  display: inline-block;
  width: 220px;
  height: 80px;
  margin: 10px;
  border: 1px solid #CCC;
  line-height: 80px;
}

.rdp-composer-versions-history {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 999999;
  background-color: #FFF;
}

.rdp-composer-versions-history-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 80px;
  overflow: hidden;
  text-align: center;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
}

.rdp-composer-versions-history-header-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  gap: 10px;
}

.rdp-composer-versions-history-header-item-selected {
  width: 210px;
  border: 1px solid #d0d0d0;
  padding: 10px 70px;
  border-radius: 100px;
  text-transform: uppercase;
  font-weight: bold;
  color: #454545;
  cursor: pointer;
  font-family: TitilliumWeb-Regular;
  background-color: #f5f5f5;
  background-image: url("./icon-down.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: calc(100% - 8px);
}

.rdp-composer-versions-history-header-item-selected.opened {
  background-image: url("./icon-up.svg");
}

.rdp-composer-versions-history-header-item-edit {
  cursor: pointer;
  width: 42px;
  height: 42px;
  border: 1px solid #d0d0d0;
  border-radius: 100px;
  background-color: #f5f5f5;
  background-image: url("./icon-edit.svg");
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
}

.rdp-composer-versions-history-header-item-versions {
  position: fixed;
  width: 210px;
  top: 70px;
  background-color: #FFF;
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
}

.rdp-composer-versions-history-header-item-version {
  text-transform: uppercase;
  font-weight: bold;
  color: #454545;
  cursor: pointer;
  font-size: 13px;
  padding: 13px 0px;
  border-bottom: 1px solid #d0d0d0;
}

.rdp-composer-versions-history-header-item-version:last-child {
  border-bottom: 0;
}

.rdp-composer-versions-history-columns {
  background-color: #f3f3f3;
  display: flex;
  position: absolute;
  top: 80px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  gap: 1px;
}

.rdp-composer-versions-history-column {
  width: 50%;
  background-color: #FFF;
}

.rdp-composer-versions-history-column iframe {
  width: 100%;
  height: 100%;
}