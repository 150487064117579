.rdp-composer-element-header {
  position: absolute;
  padding: 10px;
  height: 44px;
  top: -44px;
  left: 0;
  right: 0;
  transition: all 0.2s linear;
  z-index: 1;
  pointer-events: none;
}

*:hover > .rdp-composer-element-header {
  top: 0;
}

.rdp-composer-element-header .rdp-composer-element-header-up-btn {
  position: absolute;
  left: 10px;
  background: #5d5d5d;
  border-radius: 30px;
  width: 34px;
  height: 34px;
  cursor: pointer;
  padding: 0;
  border: 0;
  pointer-events: initial;
}

.rdp-composer-element-header .rdp-composer-element-header-up-btn span {
  -webkit-mask: url(./up.svg) no-repeat 50% 50%;
  mask: url(./up.svg) no-repeat 50% 50%;
  -webkit-mask-size: 50%;
  mask-size: 50%;
  width: 34px;
  height: 34px;
  background: #fff;
  display: block;
}


.rdp-composer-element-header .rdp-composer-element-header-down-btn {
  position: absolute;
  left: 50px;
  background: #5d5d5d;
  border-radius: 30px;
  width: 34px;
  height: 34px;
  cursor: pointer;
  padding: 0;
  border: 0;
  pointer-events: initial;
}

.rdp-composer-element-header .rdp-composer-element-header-down-btn.alone {
  left: 10px;
}

.rdp-composer-element-header .rdp-composer-element-header-down-btn span {
  -webkit-mask: url(./down.svg) no-repeat 50% 50%;
  mask: url(./down.svg) no-repeat 50% 50%;
  -webkit-mask-size: 50%;
  mask-size: 50%;
  width: 34px;
  height: 34px;
  background: #fff;
  display: block;
}


.rdp-composer-element-header .rdp-composer-element-header-remove-btn {
  position: absolute;
  right: 10px;
  background: #5d5d5d;
  border-radius: 30px;
  width: 34px;
  height: 34px;
  cursor: pointer;
  padding: 0;
  border: 0;
  pointer-events: initial;
}

.rdp-composer-element-header .rdp-composer-element-header-remove-btn span {
  -webkit-mask: url(./trash.svg) no-repeat 50% 50%;
  mask: url(./trash.svg) no-repeat 50% 50%;
  -webkit-mask-size: 50%;
  mask-size: 50%;
  width: 32px;
  height: 32px;
  background: #fff;
  display: block;
}

.rdp-composer-element-header .rdp-composer-element-header-edit-btn {
  position: absolute;
  right: 50px;
  background: #5d5d5d;
  border-radius: 30px;
  width: 34px;
  height: 34px;
  cursor: pointer;
  padding: 0;
  border: 0;
  pointer-events: initial;
}

.rdp-composer-element-header .rdp-composer-element-header-edit-btn span {
  -webkit-mask: url(./edit.svg) no-repeat 50% 50%;
  mask: url(./edit.svg) no-repeat 50% 50%;
  -webkit-mask-size: 50%;
  mask-size: 50%;
  width: 32px;
  height: 32px;
  background: #fff;
  display: block;
}

.rdp-composer-element-header .rdp-composer-element-header-copy-btn {
  position: absolute;
  right: 90px;
  background: #5d5d5d;
  border-radius: 30px;
  width: 34px;
  height: 34px;
  cursor: pointer;
  padding: 0;
  border: 0;
  pointer-events: initial;
}

.rdp-composer-element-header .rdp-composer-element-header-copy-btn span {
  -webkit-mask: url(./copy.svg) no-repeat 50% 50%;
  mask: url(./copy.svg) no-repeat 50% 50%;
  -webkit-mask-size: 50%;
  mask-size: 50%;
  width: 32px;
  height: 32px;
  background: #fff;
  display: block;
}

.rdp-composer-element-header .rdp-composer-element-header-message-btn {
  position: absolute;
  right: 130px;
  background: #5d5d5d;
  border-radius: 30px;
  width: 34px;
  height: 34px;
  cursor: pointer;
  padding: 0;
  border: 0;
  pointer-events: initial;
}

.rdp-composer-element-header .rdp-composer-element-header-message-btn span {
  -webkit-mask: url(./message.svg) no-repeat 50% 50%;
  mask: url(./message.svg) no-repeat 50% 50%;
  -webkit-mask-size: 50%;
  mask-size: 50%;
  width: 32px;
  height: 32px;
  background: #fff;
  display: block;
}

