/*region element max-width*/
/*SM*/
.element-quiz-7.max-w-sm .element-quiz-7-container  {
  max-width: 760px;
}
/*MD*/
.element-quiz-7.max-w-md .element-quiz-7-container  {
  max-width: 1160px;
}
/*LG*/
.element-quiz-7.max-w-lg .element-quiz-7-container  {
  max-width: 1600px;
}
/*FULL*/
.element-quiz-7.max-w-full .element-quiz-7-container  {
  max-width: calc(100% - 60px);
}
/*endregion*/

.element-quiz-7 {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;
}

.element-quiz-7-container {
  transition: all 0.3s ease-out;
  width: 100%;
  max-width: 760px;
  margin: auto;
  position: relative;
  background: #ffffff;
  overflow: hidden;
  border: 1px solid #ddd;
  box-shadow: 0 0.4rem 1.2rem 0.2rem rgba(0, 0, 0, 0.05);
  padding: 35px;
}

.element-quiz-7-question {
  border-bottom: 1px solid #f2f2f2;
}

.element-quiz-7-question .ql-editor {
  padding: 0px;
  margin-bottom: 15px;
}

.element-quiz-7-choices {
  margin-top: 20px;
  display: flex;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
}


.element-quiz-7-choice {
  transition: all 0.3s ease-out;
  border-right: 1px solid #d2d2d2;
  cursor: pointer;
  padding: 20px 0px;
  width: 100%;
  text-align: center;
}

.element-quiz-7-choice.choice-selected {
  color: #f8f8f8;
  font-weight: bold;
}

.element-quiz-7-choice.choice-answered {
  color: #f8f8f8;
  font-weight: bold;
}

.element-quiz-7-choice:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.element-quiz-7-choice:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-right: 0px;
}

.element-quiz-7-choice:hover {
  background: #f8f8f8;
}

.element-quiz-7-choice-radio {
  width: 60px;
  height: 45px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.element-quiz-7-choice-radio::before {
  content: "";
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  display: block;
  margin: 12px auto;
  border: 1px solid #ddd;
}

.element-quiz-7-correct-choice {
  content: "";
  background: #00643e;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  display: block;
  position: absolute;
  top: 16px;
  left: 24px;
}

.element-quiz-7-choice .quill {
  width: calc(100% - 60px);
  display: inline-block;
  vertical-align: middle;
}

.element-quiz-7-choice .quill p {
  cursor: pointer;
}

.element-quiz-7-question .ql-editor strong,
.element-quiz-7-choice .ql-editor strong {
  font-weight: bold;
}

.element-quiz-7-question .ql-editor em,
.element-quiz-7-choice .ql-editor em {
  font-style: italic;
}

.element-quiz-7-question .ql-editor u,
.element-quiz-7-choice .ql-editor u {
  text-decoration: underline;
}

.element-quiz-7-choice-text {
  display: inline-block;
  transition: none!important;
}

.element-quiz-7-choice-text * {
  transition: none!important;
}

.element-quiz1-image {
  width: calc(100% - 60px);
  margin: 20px;
}

.element-quiz1-video {
  width: calc(100% - 60px);
  margin: 20px;
}

.element-quiz-7-header {
  margin-bottom: 10px;
}
.element-quiz-7-header span {
  font-weight: 600;
  text-transform: uppercase;
}

.element-quiz-7-header span:last-child {
  float: right;
  letter-spacing: 2px;
}

/* Navigation style */

.rdp-react-quiz-navigation {
  background: #f9f9f9;
  /* border-top: 1px solid #2d7b45; */
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.rdp-react-quiz-navigation-previous-btn {
  background: transparent;
  color: #2d7b45;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px;
  border: 0;
  padding: 10px 20px;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: 10px;
  cursor: pointer;
  font-family: Lato-Bold;
}

.rdp-react-quiz-navigation-next-btn,
.rdp-react-quiz-navigation-finish-btn {
  background: transparent;
  color: #2d7b45;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px;
  border: 0;
  padding: 10px 20px;
  border-radius: 2px;
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer;
  font-family: Lato-Bold;
}

.rdp-react-quiz-navigation-progress {
  width: 300px;
  height: 50px;
  margin: 0px auto;
}

.rdp-react-quiz-navigation-progress-bar-placeholder {
  width: 260px;
  height: 6px;
  border-radius: 50px;
  background: #ccc;
  margin-top: 23px;
  display: inline-block;
}

.rdp-react-quiz-navigation-progress-bar {
  width: 0px;
  height: 6px;
  border-radius: 50px;
  background: #2d7b45;
  transition: all 0.3s ease-out;
}

.rdp-react-quiz-navigation-progress-count {
  font-weight: bold;
  font-size: 11px;
  margin-left: 5px;
  color: #2d7b45;
  letter-spacing: 2px;
}

.rdp-react-quiz-result {
  height: 450px;
  overflow: hidden;
}

.rdp-react-quiz-result-icon {
  width: 100px;
  height: 100px;
  display: block;
  margin: 50px auto 30px;
  /* background-image: url("./icon-success.png"); */
  background-size: 100%;
}

.rdp-react-quiz-result-message {
  color: #6b6b6b;
  font-size: 20px;
  font-weight: 1000;
  font-family: Lato-Bold;
  display: block;
  text-align: center;
  line-height: 30px;
}

.rdp-react-quiz-remake-btn {
  transition: all 0.3s ease-out;
  cursor: pointer;
  padding: 6px;
  width: fit-content;
  display: block;
  margin: 40px auto;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 4px;
}

.rdp-react-quiz-remake-btn:hover {
  border: 1px dashed #f1f1f1;
}

.rdp-react-quiz-remake-btn-icon {
  width: 25px;
  height: 38px;
  display: block;
  margin: auto;
  /* background-image: url("./icon-refresh.svg"); */
  background-size: 100%;
  text-align: center;
}

.rdp-react-quiz-remake-btn-text {
  margin-top: 10px;
  font-size: 13px;
  font-weight: 600;
}

.element-quiz-7-feedback-container {
  width: 100%;
  padding: 20px 0px;
  margin-top: 20px;
  transition: all 0.3s ease-out;
}
.element-quiz-7-btn-confirm {
  transition: all 0.3s ease-out;
  display: block;
  margin: 0px auto;
  background: #2d7b45;
  color: #fff;
  border: 0;
  padding: 8px 30px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 30px;
  cursor: pointer;
}

.element-quiz-7-feedback-msg {
  transition: all 0.3s ease-out;
  text-align: center;
  line-height: 1.42;
}

.element-quiz-7-feedback-msg p {
  margin: 10px auto;
  font-weight: 500;
  color: #333;
}
