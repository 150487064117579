.rdp-element-accordion-properties .rdp-composer-element-edit-body {
  padding: 0;
  top: 122px;
}

.element-accordion-properties-configurations {
  padding: 15px;
}

.element-accordion-properties-item {
  position: relative;
  padding: 15px;
  padding-bottom: 30px;
  padding-top: 30px;
  padding-left: 30px;
  border-bottom: 1px solid #ededed;
  transition: all 0.2s linear;
}

.element-accordion-properties-item-new {
  border: 1px solid #a3cb66;
  border-radius: 4px;
  color: #80b33a;
  background-color: #fff;
  padding: 12px 20px 12px 35px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 9px;
  cursor: pointer;
  position: relative;
  transition: 0.2s all linear;
  margin: 8px auto;
  display: block;
}

.element-accordion-properties-item-new:before {
  content: "";
  background-image: url("./icon-plus.svg");
  background-size: 15px;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 12px;
  top: 10px;
}

.element-accordion-properties-item-drag {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 30px;
  background-image: url("./icon-sort.svg");
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  cursor: move;
  transition: all 0.2s linear;
  display: none;
}

.element-accordion-properties-item:hover .element-accordion-properties-item-drag {
  opacity: 0.4;
}

.element-accordion-properties-item-remove {
  position: absolute;
  right: 10px;
  top: 24px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-image: url("./icon-remove.svg");
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: center;
}

.element-accordion-properties-item:hover {
  background: #f6f6f6;
}

.element-accordion-properties-item-label {
  width: 100%;
  display: block;
  color: #5c5c5c;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  margin-bottom: 5px;

  font-size: 16px;
  text-transform: unset;
  color: #000;
}

.element-quiz-6-properties-item-title {
  width: 100%;
  line-height: 20px;
  font-size: 14px !important;
  margin-bottom: 10px;
}
.element-quiz-6-properties-item-title span {
  font-size: 16px !important;
}

.element-accordion-properties-item-text {
  font-size: 10px;
  margin-top: 10px;
  cursor: not-allowed;
}

.element-accordion-properties-item-text strong {
  font-weight: bold;
}

.element-accordion-properties-item-text strong {
  font-weight: bold;
}

.element-accordion-properties-item-text em {
  font-style: italic;
}

.element-accordion-properties-item-text u {
  text-decoration: underline;
}

.element-accordion-properties-item-text * {
  font-size: 14px !important;
  line-height: 16px;
}

.element-accordion-properties-item-upload-media {
  border-top: 1px solid #ededed;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
}

.element-accordion-properties-item-upload-image,
.element-accordion-properties-item-upload-video {
  width: 140px;
  height: 40px;
  background: transparent;
  border: 0;
  font-size: 12px;
  color: #707070;
  position: relative;
  text-align: left;
  padding-left: 24px;
  cursor: pointer;
}

.element-accordion-properties-item-upload-image:before {
  content: "";
  background-image: url("./icon-image.svg");
  background-size: 100%;
  position: absolute;
  width: 15px;
  height: 15px;
  left: 0;
  top: 12px;
  opacity: 0.5;
}

.element-accordion-properties-item-upload-video:before {
  content: "";
  background-image: url("./icon-video.svg");
  background-size: 100%;
  position: absolute;
  width: 15px;
  height: 15px;
  left: 0;
  top: 12px;
  opacity: 0.5;
}

.element-accordion-properties-item-image-container {
  padding-top: 30px;
  padding-bottom: 20px;
  position: relative;
}

.element-accordion-properties-item-image {
  width: 80px;
  vertical-align: middle;
}

.element-accordion-properties-item-image-remove {
  vertical-align: middle;
  display: inline-block;
  width: calc(100% - 100px);
  text-align: right;
  font-size: 12px;
  color: #707070;
  cursor: pointer;
  text-decoration: underline;
  color: red;
}

.element-accordion-properties-item-image-remove:before {
  content: "";
  position: absolute;
  width: 1px;
  background: #e9e9e9;
  top: 30px;
  bottom: 20px;
  left: 95px;
}

.element-accordion-properties-item-video-container {
  padding-top: 30px;
  padding-bottom: 20px;
  position: relative;
}

.element-accordion-properties-item-video {
  width: 80px;
  vertical-align: middle;
}

.element-accordion-properties-item-video-remove {
  vertical-align: middle;
  display: inline-block;
  width: calc(100% - 100px);
  text-align: right;
  font-size: 12px;
  color: #707070;
  cursor: pointer;
  text-decoration: underline;
  color: red;
}

.element-accordion-properties-item-video-remove:before {
  content: "";
  position: absolute;
  width: 1px;
  background: #e9e9e9;
  top: 30px;
  bottom: 20px;
  left: 95px;
}

.element-quiz-6-properties-choices.disabled .element-quiz-6-properties-choice-radio {
  pointer-events: none;
  opacity: 0.1;
}

.element-quiz-6-properties-choice {
  cursor: pointer;
  padding: 1px 4px;
}

.element-quiz-6-properties-choice:nth-child(even) {
  background-color: #f9f9f9;
}

.element-quiz-6-properties-choice-radio {
  width: 20px;
  height: 42px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin-right: 12px;
}

.element-quiz-6-properties-choice-radio::before {
  content: "";
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  display: block;
  margin: 10px auto;
  border: 1px solid #707070;
}

.element-quiz-6-properties-correct-choice {
  content: "";
  background: #00643e;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  display: block;
  position: absolute;
  top: 14px;
  left: 4px;
}

.element-quiz-box-choices {
  position: relative;
  width: 100%;
  padding-bottom: 4px;
  margin-bottom: 4px;
}

.element-quiz-box-choices ul {
  border-bottom: 1px solid #ddd;
}

.element-accordion-properties-question-label {
  display: block;
  font-size: 15px;
  width: 100%;
  font-weight: 600;
  border-bottom: 1px solid #ddd;
  padding-bottom: 6px;
  margin-bottom: 10px;
}

.element-quiz-6-properties-item-label {
  display: block;
  font-size: 15px;
  width: 100%;
  font-weight: 600;
  padding-bottom: 8px;
  border-bottom: 1px solid #ddd;
}

.element-quiz-6-properties-choice-label {
  display: inline-block;
  line-height: 40px;
  width: calc(100% - 46px);
}

.element-quiz-6-properties-choice-label span {
  font-size: 14px !important;
}

.element-quiz-6-prop-choice-btn-rm {
  display: inline-block;
  width: 12px;
  height: 16px;
  vertical-align: middle;
  background-image: url("./icon-remove.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.default-selected-color {
  display: inline-block;
  width: 23px;
  height: 8px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ddd;
  margin-left: 4px;
}

.element-accordion-properties-choice-new {
  border: 1px solid #a3cb66;
  border-radius: 4px;
  color: #80b33a;
  padding: 12px 20px 12px 35px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 9px;
  cursor: pointer;
  position: relative;
  transition: 0.2s all linear;
  margin: 8px auto;
  display: block;
  background-color: #fff;
}

.element-accordion-properties-choice-new:before {
  content: "";
  background-image: url("./icon-plus.svg");
  background-size: 15px;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 12px;
  top: 10px;
}

.element-accordion-properties-item-new:active,
.element-accordion-properties-choice-new:active {
  background-color: #a3cb66;
}

.element-quiz-box-feedback {
  position: relative;
}

.element-quiz-box-feedback-label {
  margin-bottom: 10px;
}

.element-quiz-box-feedback-input {
  border: 0px;
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding: 6px 4px;
  margin: 5px 2px;
}

.rdp-composer-element-properties-quiz-type-container {
  margin-top: 20px;
  padding: 0 20px;
}

.element-quiz-6-properties-choice-checkbox {
  width: 20px;
  height: 42px;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin-right: 12px;
}

.element-quiz-6-properties-choice-checkbox::before {
  content: "";
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius: 0px;
  display: block;
  margin: 10px auto;
  border: 1px solid #707070;
}

.element-quiz-6-properties-correct-checkbox-choice {
  content: "";
  background: transparent;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  display: block;
  position: absolute;
  top: 14px;
  left: 4px;
}


/* Quiz 4 */
.element-quiz-4-properties-item-label {
  display: block;
  font-size: 15px;
  width: 100%;
  font-weight: 600;
  padding-bottom: 8px;
  border-bottom: 1px solid #ddd;
}

.element-quiz-4-properties-choice-label {
  display: inline-block;
  line-height: 40px;
  width: calc(100% - 46px);
}

.element-quiz-4-properties-choice-label span {
  font-size: 14px !important;
}

.element-quiz-4-prop-choice-btn-rm {
  display: inline-block;
  width: 19px;
  height: 19px;
  vertical-align: middle;
  background-image: url("./icon-remove.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 25px;
}
/* Quiz 4 */

/* Quiz 3*/
.element-quiz-3-properties-item-label {
  display: block;
  font-size: 15px;
  width: 100%;
  font-weight: 600;
  padding-bottom: 8px;
  border-bottom: 1px solid #ddd;
}

.element-quiz-3-properties-choice-label {
  display: inline-block;
  line-height: 40px;
  width: calc(100% - 46px);
}

.element-quiz-3-properties-choice-label span {
  font-size: 14px !important;
}

.element-quiz-3-prop-choice-btn-rm {
  display: inline-block;
  width: 19px;
  height: 19px;
  vertical-align: middle;
  background-image: url("./icon-remove.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 25px;
}
/* Quiz 3*/