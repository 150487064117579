.rdp-composer-element-image {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 25px;
  cursor: pointer;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.2);
}

.rdp-composer-element-edit-textarea {
  width: 100%;
  min-height: 300px;
}