.element-video-1 {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: block;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.element-video-1:hover {
  outline: 1px dashed #CCC!important;
  outline-offset: -1px;
}

.video-js {
  display: block;
  width: 100%;
  max-width: 760px;
  margin: auto;
  position: relative !important;
  width: 100% !important;
  height: auto !important;
}

.video-js .vjs-tech {
  position: unset!important;
}

.video-js .vjs-poster {
  position: absolute!important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.video-js .vjs-big-play-button {
  top: 50%!important;
  margin-top: -24px!important;
  left: 50%!important;
  margin-left: -45px!important;
}

.element-video-1 video {
  display: block;
  width: 100%;
  max-width: 760px;
  margin: auto;
}