/*region element max-width*/
/*SM*/
.element-image-with-text.max-w-sm .element-image-with-text-container-center  {
  max-width: 760px;
}
/*MD*/
.element-image-with-text.max-w-md .element-image-with-text-container-center  {
  max-width: 1160px;
}
/*LG*/
.element-image-with-text.max-w-lg .element-image-with-text-container-center  {
  max-width: 1600px;
}
/*FULL*/
.element-image-with-text.max-w-full .element-image-with-text-container-center  {
  max-width: calc(100% - 60px);
}
/*endregion*/

.element-image-with-text .ql-toolbar.ql-bubble {
  border: 0;
}
.element-image-with-text .ql-container.ql-bubble {
  width: 100%;
  transition: all 0.2s linear;
  border: 0;
}

.element-image-with-text .ql-editor,
.element-image-with-text .editor {
  /* font-size: 30px; */
  /* line-height: 150%; */
  padding: 0;
  overflow: hidden;
}

.element-image-with-text .ql-editor *,
.element-image-with-text .editor * {
  line-height: 150%;
}

.element-image-with-text .ql-editor strong {
  font-weight: bold;
}

.element-image-with-text .ql-editor em {
  font-style: italic;
}

.element-image-with-text .ql-editor u {
  text-decoration: underline;
}

.element-image-with-text .ql-bubble .ql-editor h1 {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 10px;
  font-weight: bold;
}

.element-image-with-text .ql-bubble .ql-editor h2 {
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
}

.element-image-with-text {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;
}

.element-image-with-text-container {
  width: 100%;
  padding: 60px 0px;
  display: block;
  margin: auto;
  position: relative;
  background-size: cover;
  background-position: center;
}

.element-image-with-text-container-opacity {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.element-image-with-text-container img {
  display: inline-block;
  vertical-align: middle;
}

.element-image-with-text-container-center {
  width: 100%;
  display: block;
  margin: auto;
  position: relative;
}

.element-image-with-text-container-line {
  display: block;
  padding-bottom: 20px;
  position: relative;
}

.element-image-with-text-container-divider {
  width: 90px;
  height: 4px;
  border-radius: 100px;
  background-color: #fff;
  position: absolute;
  top: 0px;
}

.element-image-with-text-container-text {
  width: 58%;
  /* width: calc(50% - 40px);
  margin-left: 40px; */
}

.mobile .element-image-with-text img {
  max-width: 100%;
}

.element-image-with-text-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
}

.element-image-with-text-modal .element-image-with-text-modal-header {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.element-image-with-text-modal-header-zoom-btns {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 60px;
  overflow: hidden;
}

.element-image-with-text-modal-header-zoom-btn {
  width: 50px;
  height: 60px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

.element-image-with-text-modal-header-zoom-btn svg {
  width: 20px;
  height: 100%;
}

.element-image-with-text-modal-header-close-btn {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  cursor: pointer;
}

.element-image-with-text-modal-header-close-btn svg {
  width: 20px;
  height: 100%;
}

.element-image-with-text-modal .element-image-with-text-modal-body {
  position: absolute;
  top: 60px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.element-image-with-text-modal .react-transform-wrapper {
  display: block;
  margin: auto;
}

.element-image-with-text-modal .react-transform-wrapper img {
  max-width: unset;
  height: 100%;
  width: 100%;
}

.element-image-with-text-modal .react-transform-component {
  transition: none;
}

.tablet .element-image-with-text-container {
  padding: 40px 30px;
}

.mobile .element-image-with-text-container {
  padding: 40px 30px;
}

.mobile .element-image-with-text-container img {
  width: 100%!important;
  margin: 0px!important;
}

.mobile .element-image-with-text-container-text {
  width: 100%!important;
  margin: 0px!important;
}

.mobile .element-image-with-text-container.left .element-image-with-text-container-text {
  margin-top: 20px!important;
}

.mobile .element-image-with-text-container.right img {
  margin-top: 20px!important;
}

@media screen and (max-width: 768px) {
  .element-image-with-text-container {
    padding: 0px 20px;
  }

  .element-image-with-text-container img {
    width: 100%!important;
    margin: 0px!important;
  }

  .element-image-with-text-container-text {
    width: 100%!important;
    margin: 0px!important;
  }

  .element-image-with-text-container.left .element-image-with-text-container-text {
    margin-top: 20px!important;
  }

  .element-image-with-text-container.right img {
    margin-top: 20px!important;
  }
}