.rdp-element-head-1 {
  width: 100%;
  background-color: #d1d1d1;
  height: 530px;
  text-align: center;
  background-size: 100%;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}

.mobile .rdp-element-head-1,
.tablet .rdp-element-head-1 {
  background-size: auto 100%;
  background-position: center;
}

.rdp-element-head-1-category {
  text-transform: uppercase;
  border: 1px solid #fff;
  border-radius: 20px;
  color: #fff;
  display: inline-block;
  padding: 10px;
  font-size: 13px;
  margin-top: 120px;

  position: relative;
}

.rdp-element-head-1-category .ql-editor {
  padding: 0;
  line-height: inherit;
  overflow: hidden;
}


.rdp-element-head-1-avatar {
  width: 155px;
  height: 155px;
  border: 15px solid #8fd7e8;
  border-radius: 500px;
  display: block;
  margin: 50px auto auto;
  overflow: hidden;

  position: relative;
}

.rdp-element-head-1-avatar img {
  margin-left: 50%;
  margin-top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.rdp-element-head-1-author-info {
  display: block;
  margin-top: 30px;
  text-align: center;

  position: relative;
}

.rdp-element-head-1-author-name {
  color: #fff;
  font-family: Prompt-Regular;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  min-width: 20px;
}

.rdp-element-head-1-author-name .ql-editor {
  padding: 0;
  line-height: inherit;
  overflow: hidden;
  font-size: 17px;
}

.rdp-element-head-1-author-divider {
  color: #fff;
  font-family: Prompt-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 21.5px;
  line-height: 33px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;

  position: relative;
}

.rdp-element-head-1-author-office {
  color: #fff;
  font-family: Prompt-Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 21.5px;
  line-height: 33px;
  display: inline-block;
  vertical-align: middle;
  min-width: 20px;
}

.rdp-element-head-1-author-office .ql-editor {
  padding: 0;
  line-height: inherit;
  overflow: hidden;
  font-size: 21.5px;
}

.rdp-element-head-1-divider {
  width: 75px;
  height: 2px;
  display: block;
  background: #aa2c23;
  margin: 10px auto auto;
}





.rdp-element-text .ql-toolbar.ql-bubble {
  border: 0;
}
.rdp-element-text .ql-container.ql-bubble {
  /* position: absolute; */
  width: 100%;
  /* top: -30px; */
  transition: all 0.2s linear;
  border: 0;
}

.rdp-element-text .ql-toolbar {
  /* opacity: 0; */
  /* padding: 8px 0px; */
}

/* .rdp-element-text:hover .ql-toolbar  {
  opacity: 1;
} */

.rdp-element-text .ql-editor {
  font-size: 18px;
  /* line-height: 26px; */
  line-height: 150%;
  padding: 0;
  overflow: hidden;
}

.rdp-element-text .ql-editor strong {
  font-weight: bold;
}

.rdp-element-text .ql-editor em {
  font-style: italic;
}

.rdp-element-text .ql-editor u {
  text-decoration: underline;
}

.rdp-element-text .ql-bubble .ql-editor h1 {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 10px;
  font-weight: bold;
}

.rdp-element-text .ql-bubble .ql-editor h2 {
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
}

.rdp-element-text {
  padding-top: 35px;
  padding-bottom: 35px;
  overflow: hidden;
  position: relative;
}

.rdp-element-text:hover {
  outline: 1px dashed #CCC!important;
  outline-offset: -1px;
}

/* .rdp-element-text textarea, .rdp-element-text div.editor {
  width: 80%;
  display: block;
  margin: auto;
  border: 0;
  font-size: 18px;
  line-height: 26px;
  resize: none;
  overflow: hidden;
} */

.rdp-element-text div.editor b {
  font-weight: bold;
}

.rdp-element-text .quill {
  width: 80%;
  margin: auto;
}

.rdp-element-head-1-video-mask {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.rdp-element-head-1-video-mask.width {
  width: 100%;
  height: auto;
}

.rdp-element-head-1-video-mask.height {
  width: auto;
  height: 100%;
}