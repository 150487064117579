/*region element max-width*/
/*SM*/
.element-divider-1.max-w-sm .element-divider-1-content  {
  max-width: 760px;
}
/*MD*/
.element-divider-1.max-w-md .element-divider-1-content  {
  max-width: 1160px;
}
/*LG*/
.element-divider-1.max-w-lg .element-divider-1-content  {
  max-width: 1600px;
}
/*FULL*/
.element-divider-1.max-w-full .element-divider-1-content  {
  max-width: calc(100% - 60px);
}
/*endregion*/

.element-divider-1 {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: block;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.element-divider-1:hover {
  outline: 1px dashed #CCC!important;
  outline-offset: -1px;
}

.element-divider-1-content {
  width: 100%;
  display: block;
  margin: auto;
}

.element-divider-1-line {
  width: 100%;
  height: 1px;
  background-color: #e6e6e6;
}