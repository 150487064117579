.rdp-component-preloader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #FFF;
  background-image: url("./icon-preloader.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 180px;
}