/*region element max-width*/
/*SM*/
.element-image-and-text.max-w-sm .element-image-and-text-container  {
  max-width: 760px;
}
/*MD*/
.element-image-and-text.max-w-md .element-image-and-text-container  {
  max-width: 1160px;
}
/*LG*/
.element-image-and-text.max-w-lg .element-image-and-text-container  {
  max-width: 1600px;
}
/*FULL*/
.element-image-and-text.max-w-full .element-image-and-text-container  {
  max-width: calc(100% - 60px);
}
/*endregion*/

.element-image-and-text .ql-toolbar.ql-bubble {
  border: 0;
}
.element-image-and-text .ql-container.ql-bubble {
  width: 100%;
  transition: all 0.2s linear;
  border: 0;
}

.element-image-and-text .ql-editor {
  font-size: 18px;
  line-height: 150%;
  padding: 0;
  overflow: hidden;
}

.element-image-and-text .ql-editor strong {
  font-weight: bold;
}

.element-image-and-text .ql-editor em {
  font-style: italic;
}

.element-image-and-text .ql-editor u {
  text-decoration: underline;
}

.element-image-and-text .ql-bubble .ql-editor h1 {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 10px;
  font-weight: bold;
}

.element-image-and-text .ql-bubble .ql-editor h2 {
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
}

.element-image-and-text {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;
}

.element-image-and-text-container {
  width: 100%;
  display: block;
  margin: auto;
}

.element-image-and-text-container img {
  display: inline-block;
  vertical-align: middle;
}

.element-image-and-text-container video {
  display: inline-block;
  vertical-align: middle;
}

.element-image-and-text-container-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.42;
  /* width: calc(50% - 40px);
  margin-left: 40px; */
}

.mobile .element-image-and-text img {
  max-width: 100%;
}
.mobile .element-image-and-text video {

  max-width: 100%;
}

.element-image-and-text-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
}

.element-image-and-text-modal .element-image-and-text-modal-header {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.element-image-and-text-modal-header-zoom-btns {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 60px;
  overflow: hidden;
}

.element-image-and-text-modal-header-zoom-btn {
  width: 50px;
  height: 60px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

.element-image-and-text-modal-header-zoom-btn svg {
  width: 20px;
  height: 100%;
}

.element-image-and-text-modal-header-close-btn {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  cursor: pointer;
}

.element-image-and-text-modal-header-close-btn svg {
  width: 20px;
  height: 100%;
}

.element-image-and-text-modal .element-image-and-text-modal-body {
  position: absolute;
  top: 60px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.element-image-and-text-modal .react-transform-wrapper {
  display: block;
  margin: auto;
}

.element-image-and-text-modal .react-transform-wrapper img {
  max-width: unset;
  height: 100%;
  width: 100%;
}

.element-image-and-text-modal .react-transform-wrapper video {
  max-width: unset;
  height: 100%;
  width: 100%;
}

.element-image-and-text-modal .react-transform-component {
  transition: none;
}

.mobile .element-image-and-text-container {
  padding: 0px 20px;
}

.mobile .element-image-and-text-container img {
  width: 100%!important;
  margin: 0px!important;
}

.mobile .element-image-and-text-container video {
  width: 100%!important;
  margin: 0px!important;
}

.mobile .element-image-and-text-container-text {
  width: 100%!important;
  margin: 0px!important;
}

.mobile .element-image-and-text-container.left .element-image-and-text-container-text {
  margin-top: 20px!important;
}

.mobile .element-image-and-text-container.right img {
  margin-top: 20px!important;
}

.mobile .element-image-and-text-container.right video {
  margin-top: 20px!important;
}


@media screen and (max-width: 768px) {
  .element-image-and-text-container {
    padding: 0px 20px;
  }

  .element-image-and-text-container img,
  .element-image-and-text-container .video {
    width: 100%!important;
    margin: 0px!important;
  }

  .element-image-and-text-container-text {
    width: 100%!important;
    margin: 0px!important;
  }

  .element-image-and-text-container.left .element-image-and-text-container-text {
    margin-top: 20px!important;
  }

  .element-image-and-text-container.right img {
    margin-top: 20px!important;
  }

  .element-image-and-text-container.right video {
    margin-top: 20px!important;
  }
}