.rdp-dam-widget {
  position: fixed;
  z-index: 10000;
  /* background: rgba(245, 245, 245, 0.8); */
  /* background: rgba(0, 0, 0, 0.5); */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rdp-dam-widget-modal {
  position: absolute;
  width: 500px;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  background: #FFFFFF;
  overflow: hidden;
}

.rdp-dam-widget-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  z-index: 2;
}

.rdp-dam-widget-header {
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  font-size: 20px;
  font-weight: bold;
  color: #2d7b45;
}

.rdp-dam-widget-header-close {
  width: 50px;
  height: 50px;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  background: #5d5d5d;
  cursor: pointer;
  -webkit-mask: url(./close.svg) no-repeat 50% 50%;
  mask: url(./close.svg) no-repeat 50% 50%;
  -webkit-mask-size: 50%;
  mask-size: 50%;
}

.rdp-dam-widget-tabs {
  height: 50px;
  box-shadow: 0 3px 5px 0px #e6e6e6;
}

.rdp-dam-widget-tabs-item {
  color: #5d5d5d;
  display: inline-block;
  line-height: 50px;
  padding: 0 15px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
}

.rdp-dam-widget-tabs-item.active {
  border-bottom: 3px solid #2d7b45;
  line-height: 47px;
}

.rdp-dam-widget-body {
  position: absolute;
  top: 100px;
  bottom: 60px;
  left: 0;
  right: 0;
  padding: 15px;
  overflow-x: hidden;
  overflow-y: auto;
}

.rdp-dam-widget-label {
  display: block;
  font-weight: bold;
}

.rdp-dam-widget-input {
  width: 100%;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 25px;
  padding-left: 12px;
  border: 0px solid rgba(0, 0, 0, 0.25);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
  border-radius: 4px;
  position: relative;
}

.rdp-dam-widget-input::-webkit-outer-spin-button,
.rdp-dam-widget-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rdp-dam-widget-images,
.rdp-dam-widget-audios,
.rdp-dam-widget-videos {
  padding: 5px;
  position: absolute;
  /* top: 50px; */
  top: 130px;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  z-index: 1;
}

.rdp-dam-widget-images li {
  cursor: pointer;
  width: 146.5px;
  height: 146.5px;
  display: inline-block;
  background-position: 50% center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid #f5f5f5;
  margin: 5px;
}

.rdp-dam-widget-images li.active {
  border: 1px solid #7d7d7d;
  opacity: 0.5;
}

.rdp-dam-widget-videos {
  padding: 5px;
}

.rdp-dam-widget-videos li {
  cursor: pointer;
  width: 146.5px;
  height: 146.5px;
  display: inline-block;
  background-position: 50% center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid #f5f5f5;
  margin: 5px;
  position: relative;
}

.rdp-dam-widget-videos li video {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.rdp-dam-widget-videos li .play {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  /* background-image: url(./video_play.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px;
  opacity: 0.4;
}

.rdp-dam-widget-videos li.active {
  border: 1px solid #7d7d7d;
  opacity: 0.5;
}

.rdp-dam-widget-audios {
  width: 100%;
  padding: 5px;
}

.rdp-dam-widget-audios li {
  cursor: pointer;
  width: 100%;
  display: inline-block;
  background-position: 50% center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 5px;
  margin-bottom: 15px;
  position: relative;
}

.rdp-dam-widget-audios li audio {
  width: calc(100% - 130px);
  display: inline-block;
  vertical-align: middle;
}

.rdp-dam-widget-select {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  background: #2d7b45;
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 20px;
}

.rdp-dam-widget-footer {
  position: absolute;
  width: 100%;
  height: 60px;
  bottom: 0;
  text-align: right;
  padding: 9px 15px;
}

.rdp-dam-widget-submit {
  background: #2d7b45;
  color: #fff;
  border: 0;
  padding: 8px 30px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 30px;
  cursor: pointer;
}

.rdp-dam-widget-upload {
  border: 1px solid #e7e7e7;
  border-style: dashed;
  border-radius: 4px;
  position: relative;
  text-align: center;
  font-size: 15px;
  padding: 10px 0px;
  overflow: hidden;
  cursor: pointer;
}

.rdp-dam-widget-upload input {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.rdp-dam-widget-upload-progress {
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #CCC;
  transition: all 0.2s linear;
  background: rgb(45, 123, 69, 0.2);
}

.rdp-dam-widget-preloader {
  position: absolute;
  top: 130px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-image: url("./preloader.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 180px;
}

.rdp-dam-widget-iframe-modal {
  position: fixed;
  z-index: 99999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rdp-dam-widget-iframe-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.rdp-dam-widget-iframe {
  position: absolute;
  background-color: #FFFFFF;
  background-image: url("./preloader.gif");
  background-position: center calc(50% + 40px);
  background-repeat: no-repeat;
  background-size: 180px;
  top: 80px;
  bottom: 80px;
  left: 80px;
  right: 80px;
  z-index: 2;
  width: calc(100% - 160px);
  height: calc(100% - 160px);
}