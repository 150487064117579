.rdp-composer-element-quote-2-background {
  width: 100%;
  /* border-radius: 5px; */
  margin-top: 10px;
  margin-bottom: 25px;
  cursor: pointer;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.2);
}

.rdp-composer-element-quote-2-avatar {
  width: 140px;
  height: 140px;
  border-radius: 200px;
  display: block;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 25px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.2);
}

.rdp-composer-element-quote-2-avatar img {
  width: 100%;
}

.rdp-composer-element-quote-2-avatar.add-avatar {
  border: 5px solid #e7e7e7;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdp-composer-element-quote-2-avatar.add-avatar img {
  width: 80px;
  height: auto;
}

.rdp-composer-element-quote-2-avatar-options {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.rdp-composer-element-quote-2-avatar-remove,
.rdp-composer-element-quote-2-avatar-restore-default,
.rdp-composer-element-quote-2-avatar-add {
  background: #e7e7e7;
  color: #000;
  border: 0;
  padding: 11px 26px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer;
  min-width: 130px;
  margin-right: 10px;
}

.rdp-composer-element-quote-2-avatar-remove:disabled,
.rdp-composer-element-quote-2-avatar-restore-default:disabled,
.rdp-composer-element-quote-2-avatar-add:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/*region Checkbox*/
.rdp-composer-element-quote-2-input-checkbox-container {
  margin-bottom: 25px;
}

.rdp-composer-element-quote-2-input-list {
  margin-top: 10px;
}

.rdp-composer-element-quote-2-input-list-item {
  padding: 1px 4px;
}

.rdp-composer-element-quote-2-input-checkbox-label {
  position: relative;
  display: block;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rdp-composer-element-quote-2-input-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.rdp-composer-element-quote-2-input-checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #ddd;
}

.rdp-composer-element-quote-2-input-checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.rdp-composer-element-quote-2-input-checkbox-label input:checked ~ .rdp-composer-element-quote-2-input-checkbox-checkmark:after {
  display: block;
}

.rdp-composer-element-quote-2-input-checkbox-label .rdp-composer-element-quote-2-input-checkbox-checkmark:after {
  left: 9px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid #00643e;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.rdp-composer-element-quote-2-input-checkbox:checked {
  background-color: #777;
}

.rdp-composer-element-quote-2-input-checkbox-text {

}
/*endregion Checkbox*/