/*region element max-width*/
/*SM*/
.element-note-1.max-w-sm .rdp-element-note-1-container  {
  max-width: 760px;
}
/*MD*/
.element-note-1.max-w-md .rdp-element-note-1-container  {
  max-width: 1160px;
}
/*LG*/
.element-note-1.max-w-lg .rdp-element-note-1-container  {
  max-width: 1600px;
}
/*FULL*/
.element-note-1.max-w-full .rdp-element-note-1-container  {
  max-width: calc(100% - 60px);
}
/*endregion*/

.rdp-element-note-1 .ql-toolbar.ql-bubble {
  border: 0;
}
.rdp-element-note-1 .ql-container.ql-bubble {
  /* position: absolute; */
  width: 100%;
  /* top: -30px; */
  transition: all 0.2s linear;
  border: 0;
}

.rdp-element-note-1 .ql-toolbar {
  /* opacity: 0; */
  /* padding: 8px 0px; */
}

/* .rdp-element-note-1:hover .ql-toolbar  {
  opacity: 1;
} */

.rdp-element-note-1 .ql-editor {
  padding: 0;
  overflow: hidden;
}

.rdp-element-note-1 .ql-editor * {
  line-height: 150%;
}

.rdp-element-note-1 .editor * {
  line-height: 150%;
}

.rdp-element-note-1 .ql-editor strong {
  font-weight: bold;
}

.rdp-element-note-1 .ql-editor em {
  font-style: italic;
}

.rdp-element-note-1 .ql-editor u {
  text-decoration: underline;
}

.rdp-element-note-1 .ql-bubble .ql-editor h1 {
  font-size: 30px;
  line-height: 40px;
  margin-bottom: 10px;
  font-weight: bold;
}

.rdp-element-note-1 .ql-bubble .ql-editor h2 {
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
}

.rdp-element-note-1 {
  padding-top: 35px;
  padding-bottom: 35px;
  overflow: hidden;
  position: relative;
}

.rdp-element-note-1:hover {
  outline: 1px dashed #CCC!important;
  outline-offset: -1px;
}

.rdp-element-note-1 textarea, .rdp-element-note-1 div.editor {
  width: 100%;
  display: block;
  margin: auto;
  border: 0;
  font-size: 18px;
  line-height: 26px;
  resize: none;
  overflow: hidden;
}

.rdp-element-note-1 div.editor b {
  font-weight: bold;
}

.rdp-element-note-1 .quill {
  /* width: 80%; */
  width: 100%;
  max-width: 760px;
  margin: auto;
}

.mobile .rdp-element-note-1 {
  padding-left: 20px;
  padding-right: 20px;
}

.rdp-element-note-1-container {
  width: 100%;
  display: block;
  margin: auto;
  padding: 20px 40px;
  border-radius: 5px;
  border: 1px solid #e9eaeb;
}

.rdp-element-note-1-container-icon {
  width: 30px;
  display: block;
  margin: auto;
  margin-bottom: 15px;
}

.rdp-element-note-1-container-icon svg {
  width: 100%;
  height: 100%;
}

.ql-format-custom-color {
  width: 28px;
  height: 24px;
  cursor: pointer;
  display: inline-block;
  background-image: url("./icon-paint.svg");
  background-size: 13px;
  background-position: center;
  background-repeat: no-repeat;
}

.ql-custom-color-picker {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.ql-custom-color-picker-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
} 

.ql-custom-color-picker .twitter-picker {
  position: fixed;
  /* top: 60px; */
  z-index: 2;
  /* right: 400px; */
  text-align: center;
  border: 0;
  border-bottom: 1px solid #f3f3f3;
  height: 80px;
  margin: 0;
  padding-top: 10px;
}

.ql-custom-color-picker .twitter-picker * {
  display: inline-block!important;
  float: none!important;
  vertical-align: top;
  line-height: 30px;
}

.mobile .rdp-element-note-1-container {
  padding: 35px 10px;
}

@media screen and (max-width: 768px) {
  .rdp-element-note-1 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .rdp-element-note-1-container {
    padding: 35px 10px;
  }
}