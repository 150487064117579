/*region element max-width*/
/*SM*/
.element-list-1.max-w-sm .element-list-items,
.element-list-2.max-w-sm .element-list-items,
.element-list-3.max-w-sm .element-list-items {
  max-width: 760px;
}
/*MD*/
.element-list-1.max-w-md .element-list-items,
.element-list-2.max-w-md .element-list-items,
.element-list-3.max-w-md .element-list-items {
  max-width: 1160px;
}
/*LG*/
.element-list-1.max-w-lg .element-list-items,
.element-list-2.max-w-lg .element-list-items,
.element-list-3.max-w-lg .element-list-items {
  max-width: 1600px;
}
/*FULL*/
.element-list-1.max-w-full .element-list-items,
.element-list-2.max-w-full .element-list-items,
.element-list-3.max-w-full .element-list-items {
  max-width: calc(100% - 60px);
}
/*endregion*/

.element-list-1, .element-list-2, .element-list-3 {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;
}

.element-list-items {
  width: 100%;
  display: block;
  margin: auto;
}

.element-list-item {
  position: relative;
  margin-bottom: 30px;
}

.element-list-item:hover {
  border-top: 0;
}

.element-list-item-border {
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 5px;
  width: 3px;
  z-index: 1;
  display: none;
}

.element-list-item-title {
  cursor: pointer;
  display: block;
  font-size: 18px;
  font-weight: bold;
  padding: 35px 30px;
}

.element-list-item-number {
  background: #297c43;
  color: #FFF;
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 100px;
  text-align: center;
  line-height: 40px;
  vertical-align: top;
  font-size: 18px;
}

.element-list-item-checkbox {
  width: 26px;
  height: 26px;
  margin-top: 6px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  vertical-align: top;
  font-size: 18px;
  border-width: 2px;
  border-radius: 3px;
  border-style: solid;
  border-color: #297c43;
  cursor: pointer;
  position: relative;
}

.element-list-item-checkbox-checked {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.element-list-item-checkbox-checked svg {
  width: 18px;
  height: 100%;
  vertical-align: top;
}

.element-list-item-bullet {
  width: 8px;
  height: 8px;
  margin-left: 18px;
  margin-top: 6px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  vertical-align: top;
  font-size: 18px;
  border-radius: 100px;
  background-color: #297c43;
  cursor: pointer;
  position: relative;
}

.element-list-item-text {
  width: calc(100% - 80px);
  display: inline-block;
  vertical-align: top;
  margin-left: 40px;
}

.element-list-2 .element-list-item-text,
.element-list-3 .element-list-item-text {
  margin-left: 54px;
}

.element-list-item-text .ql-editor {
  padding: 0;
  line-height: 1.42;
}

.element-list-item-text .ql-editor strong {
  font-weight: bold;
}

.element-list-item-text .ql-editor strong {
  font-weight: bold;
}

.element-list-item-text .ql-editor em {
  font-style: italic;
}

.element-list-item-text .ql-editor u {
  text-decoration: underline;
}

.element-list-item-image {
  width: calc(100% - 60px);
  margin: 30px;
  margin-top: 0;
}

.element-list-item-video {
  width: calc(100% - 60px);
  margin: 30px;
  margin-top: 0;
}

.mobile .element-list-items {
  padding: 0px 20px;
}

@media screen and (max-width: 768px) {
  .element-list-items {
    padding: 0px 20px;
  }

  .element-list-item-text {
    width: calc(100% - 60px);
    margin-left: 20px;
  }

  .element-list-2 .element-list-item-text,
  .element-list-3 .element-list-item-text {
    margin-left: 34px;
  }
}