.rdp-composer-subheader-embed {
  height: 56px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0px;
  z-index: 1;
  border-bottom: 1px solid #eee;
  vertical-align: top;
  padding: 0px 4px 0px 26px;
}

.rdp-composer-subheader-embed-title {
  font-family: "Inter-Bold";
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
  color: #764ab0;
  line-height: 54px;
}

.rdp-composer-subheader-embed-actions {
  position: absolute;
  right: 0px;
  top: 0px;
  width: auto;
  padding-right: 19px;
  padding-top: 7px;
}


.rdp-composer-subheader-embed-new {
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  background: #4d7aff;
  color: #FFFFFF;
  margin-bottom: 12px;
  text-transform: uppercase;
  font-size: 11px;
  padding-left: 60px;
  border: 0;
  font-weight: bold;
  padding-right: 16px;
  line-height: 20px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  transition: 0.2s all linear;
}

.rdp-composer-subheader-embed-new::before {
  background-image: url("./icon-plus-white.svg");
  content: '';
  background-size: 100%;
  position: absolute;
  width: 18px;
  height: 18px;
  left: 15px;
  top: 11px;
}

.rdp-composer-subheader-embed-new::after {
  content: '';
  border-right: 1px solid #fff;
  position: absolute;
  width: 2px;
  height: 100%;
  left: 46px;
  top: 0px;
  opacity: 0.4;
}