/*region element max-width*/
/*SM*/
.element-quote-5.max-w-sm .rdp-element-quote-5-container {
  max-width: 760px;
}
/*MD*/
.element-quote-5.max-w-md .rdp-element-quote-5-container {
  max-width: 1160px;
}
/*LG*/
.element-quote-5.max-w-lg .rdp-element-quote-5-container {
  max-width: 1600px;
}
/*FULL*/
.element-quote-5.max-w-full .rdp-element-quote-5-container {
  max-width: calc(100% - 60px);
}
/*endregion*/

.rdp-element-quote-5 {
  width: 100%;
  background-color: #d1d1d1;
  text-align: center;
  background-size: 100%;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}

.rdp-element-quote-5-container {
  width: 100%;
  max-width: 760px;
  display: block;
  margin: auto;
  padding: 50px 0px;
}

.mobile .rdp-element-quote-5,
.tablet .rdp-element-quote-5 {
  background-size: auto 100%;
  background-position: center;
}

.mobile .rdp-element-quote-5-container,
.tablet .rdp-element-quote-5-container {
  padding: 50px 30px;
}

.mobile .rdp-element-quote-5-text-container-icon,
.tablet .rdp-element-quote-5-text-container-icon {
  width: 10px;
  height: 10px;
  position: absolute;
  left: -20px;
}

.rdp-element-quote-5-text-container {
  position: relative;
}

.rdp-element-quote-5-text-container-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  left: -50px;
}

.rdp-element-quote-5-text-container-icon svg {
  width: 100%;
  height: 100%;
}

.rdp-element-quote-5-text {
  color: #fff;
  margin-top: 30px;
  max-width: 540px;
  width: 100%;
  display: block;
}

.rdp-element-quote-5-text .ql-editor {
  padding: 0;
  line-height: inherit;
  overflow: hidden;
}

.rdp-element-quote-5-text .ql-editor * {
  line-height: 150%;
}

.rdp-element-quote-5-avatar {
  width: 60px;
  height: 60px;
  border-radius: 500px;
  overflow: hidden;
}

.rdp-element-quote-5-avatar img {
  margin-left: 50%;
  margin-top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.rdp-element-quote-5-author-info {
  display: block;
  text-align: left;
  margin-top: 10px;
}

.rdp-element-quote-5-author-name {
  color: #fff;
  font-family: Prompt-Regular;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 26px;
  /* display: inline-block; */
  /* vertical-align: middle; */
}

.rdp-element-quote-5-author-name .ql-editor {
  padding: 0;
  line-height: inherit;
  overflow: hidden;
  font-size: 17px;
}

.rdp-element-quote-5-author-name .ql-editor * {
  font-family: Lato-Regular!important;
}

.rdp-element-quote-5:hover {
  outline: 1px dashed #CCC!important;
  outline-offset: -1px;
}

@media screen and (max-width: 760px) {
  .rdp-element-quote-5-container {
    padding: 50px 30px;
  }

  .rdp-element-quote-5-text-container-icon {
    width: 10px;
    height: 10px;
    position: absolute;
    left: -20px;
  }
}