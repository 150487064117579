.rdp-composer-sidebar-btn {
  text-align: center;
  margin-bottom: 0px;
  padding-top: 6px;
  /* border-bottom: 1px solid #f9f9f9; */
  cursor: pointer;
  display: inline-block;
  /* margin-left: 10px; */
  /* margin-right: 10px; */
  width: 80px;
}

.rdp-composer-sidebar-btn-icon {
  background: #5d5d5d;
  width: 40px;
  height: 40px;
  display: block;
  margin: auto;
}

.rdp-composer-sidebar-btn-icon.header {
  -webkit-mask: url(./assets/header.svg) no-repeat 50% 50%;
  mask: url(./assets/header.svg) no-repeat 50% 50%;
  -webkit-mask-size: 56%;
  mask-size: 56%;
}

.rdp-composer-sidebar-btn-icon.text {
  -webkit-mask: url(./assets/text.svg) no-repeat 50% 50%;
  mask: url(./assets/text.svg) no-repeat 50% 50%;
  -webkit-mask-size: 40%;
  mask-size: 40%;
}

.rdp-composer-sidebar-btn-icon.statement {
  -webkit-mask: url(./assets/statement.svg) no-repeat 50% 50%;
  mask: url(./assets/statement.svg) no-repeat 50% 50%;
  -webkit-mask-size: 45%;
  mask-size: 45%;
}

.rdp-composer-sidebar-btn-icon.quote {
  -webkit-mask: url(./assets/quote.svg) no-repeat 50% 50%;
  mask: url(./assets/quote.svg) no-repeat 50% 50%;
  -webkit-mask-size: 45%;
  mask-size: 45%;
}

.rdp-composer-sidebar-btn-icon.list {
  -webkit-mask: url(./assets/list.svg) no-repeat 50% 50%;
  mask: url(./assets/list.svg) no-repeat 50% 50%;
  -webkit-mask-size: 45%;
  mask-size: 45%;
}

.rdp-composer-sidebar-btn-icon.image {
  -webkit-mask: url(./assets/image.svg) no-repeat 50% 50%;
  mask: url(./assets/image.svg) no-repeat 50% 50%;
  -webkit-mask-size: 45%;
  mask-size: 45%;
}

.rdp-composer-sidebar-btn-icon.gallery {
  -webkit-mask: url(./assets/gallery.svg) no-repeat 50% 50%;
  mask: url(./assets/gallery.svg) no-repeat 50% 50%;
  -webkit-mask-size: 45%;
  mask-size: 45%;
}

.rdp-composer-sidebar-btn-icon.multimedia {
  -webkit-mask: url(./assets/multimedia.svg) no-repeat 50% 50%;
  mask: url(./assets/multimedia.svg) no-repeat 50% 50%;
  -webkit-mask-size: 45%;
  mask-size: 45%;
}

.rdp-composer-sidebar-btn-icon.interactive {
  -webkit-mask: url(./assets/interactive.svg) no-repeat 50% 50%;
  mask: url(./assets/interactive.svg) no-repeat 50% 50%;
  -webkit-mask-size: 45%;
  mask-size: 45%;
}

.rdp-composer-sidebar-btn-icon.quiz {
  -webkit-mask: url(./assets/quiz.svg) no-repeat 50% 50%;
  mask: url(./assets/quiz.svg) no-repeat 50% 50%;
  -webkit-mask-size: 45%;
  mask-size: 45%;
}

.rdp-composer-sidebar-btn-icon.chart {
  -webkit-mask: url(./assets/chart.svg) no-repeat 50% 50%;
  mask: url(./assets/chart.svg) no-repeat 50% 50%;
  -webkit-mask-size: 45%;
  mask-size: 45%;
}

.rdp-composer-sidebar-btn-icon.divider {
  -webkit-mask: url(./assets/divider.svg) no-repeat 50% 50%;
  mask: url(./assets/divider.svg) no-repeat 50% 50%;
  -webkit-mask-size: 45%;
  mask-size: 45%;
}

.rdp-composer-sidebar-btn-icon.activities {
  -webkit-mask: url(./assets/activities.svg) no-repeat 50% 50%;
  mask: url(./assets/activities.svg) no-repeat 50% 50%;
  -webkit-mask-size: 50%;
  mask-size: 50%;
}

.rdp-composer-sidebar-btn-icon.markers {
  -webkit-mask: url(./assets/markers.svg) no-repeat 50% 60%;
  mask: url(./assets/markers.svg) no-repeat 50% 60%;
  -webkit-mask-size: 60%;
  mask-size: 60%;
}

.rdp-composer-sidebar-btn-icon.table {
  -webkit-mask: url(./assets/table.svg) no-repeat 50% 60%;
  mask: url(./assets/table.svg) no-repeat 50% 60%;
  -webkit-mask-size: 60%;
  mask-size: 60%;
}

.rdp-composer-sidebar-btn-label {
  font-size: 13px;
}