
/*region Checkbox*/
.rdp-composer-element-edit-permission-list {
  margin-top: 10px;
}

.rdp-composer-element-edit-permission-list-item {
  padding: 1px 4px;
}

.rdp-composer-element-edit-permission-checkbox-label {
  position: relative;
  display: block;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rdp-composer-element-edit-permission-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.rdp-composer-element-edit-permission-checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #ddd;
}

.rdp-composer-element-edit-permission-checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.rdp-composer-element-edit-permission-checkbox-label input:checked ~ .rdp-composer-element-edit-permission-checkbox-checkmark:after {
  display: block;
}

.rdp-composer-element-edit-permission-checkbox-label .rdp-composer-element-edit-permission-checkbox-checkmark:after {
  left: 9px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid #00643e;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.rdp-composer-element-edit-permission-checkbox:checked {
  background-color: #777;
}

.rdp-composer-element-edit-permission-checkbox-text {

}
/*endregion Checkbox*/