.rdp-element-video-interactive {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: block;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.rdp-element-video-interactive:hover {
  outline: 1px dashed #CCC!important;
  outline-offset: -1px;
}

.rdp-element-video-interactive video {
  display: block;
  width: 80%;
  margin: auto;
}