.rdp-component-configurations {
  position: fixed;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rdp-component-configurations .rdp-component-configurations-divider {
  height: 1px;
  background: #2d7b45;
  margin: 50px 0;
  width: 100%;
  display: block;
}

.rdp-component-configurations-modal {
  
  /* width: 600px;
  height: 450px;
  left: 50%;
  top: 50%;
  margin-left: -300px;
  margin-top: -225px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 0px 10px 0 #CCC; */
  /* width: 415px; */
  
  /* width: 500px;
  top: 0;
  bottom: 0;
  right: 0;
  background: #FFFFFF;
  overflow: hidden; */

  position: absolute;
  width: 800px;
  /* height: 700px;
  top: 50%;
  margin-top: -350px; */
  left: 50%;
  background: #FFFFFF;
  margin-left: -400px;
  overflow: hidden;
  height: 80%;
  top: 10%;
  border-radius: 5px;
}

.rdp-component-configurations-header {
  height: 70px;
  line-height: 70px;
  padding: 0 25px;
  font-size: 20px;
  font-weight: bold;
  /* color: #2d7b45; */
  color: #000000;
}

.rdp-component-configurations-header-close {
  width: 70px;
  height: 70px;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  background: #5d5d5d;
  cursor: pointer;
  -webkit-mask: url(./close.svg) no-repeat 50% 50%;
  mask: url(./close.svg) no-repeat 50% 50%;
  -webkit-mask-size: 25px;
  mask-size: 25px;
}

.rdp-component-configurations-tabs {
  position: absolute;
  height: 50px;
  top: 70px;
  left: 25px;
  right: 25px;
  border-bottom: 2px solid #d9e2eb;
}

.rdp-component-configurations-tab {
  display: inline-block;
  padding: 16px 10px;
  font-weight: bold;
  color: #909ead;
  cursor: pointer;
  margin-right: 30px;
}

.rdp-component-configurations-tab.active {
  border-bottom: 2px solid #2d7b45;
  color: #2e445b;
}

.rdp-component-configurations-tabs-item {
  color: #5d5d5d;
  display: inline-block;
  line-height: 50px;
  padding: 0 15px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
}

.rdp-component-configurations-tabs-item.active {
  border-bottom: 3px solid #2d7b45;
  line-height: 47px;
}

.rdp-component-configurations-body {
  position: absolute;
  top: 120px;
  bottom: 60px;
  left: 0;
  right: 0;
  padding: 25px;
  overflow-x: hidden;
  overflow-y: auto;
}

.rdp-component-configurations-label {
  display: block;
  font-weight: bold;
}

.rdp-component-configurations-input {
  width: 100%;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 25px;
  padding-left: 12px;
  border: 0px solid rgba(0, 0, 0, 0.25);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
  border-radius: 4px;
  position: relative;
}

.rdp-component-configurations-input::-webkit-outer-spin-button,
.rdp-component-configurations-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.rdp-component-configurations-images,
.rdp-component-configurations-videos {
  padding: 5px;
  position: absolute;
  /* top: 50px; */
  top: 130px;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;
  z-index: 1;
}

.rdp-component-configurations-images li {
  cursor: pointer;
  width: 146.5px;
  height: 146.5px;
  display: inline-block;
  background-position: 50% center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid #f5f5f5;
  margin: 5px;
}

.rdp-component-configurations-images li.active {
  border: 1px solid #7d7d7d;
  opacity: 0.5;
}

.rdp-component-configurations-videos {
  padding: 5px;
}

.rdp-component-configurations-videos li {
  cursor: pointer;
  width: 146.5px;
  height: 146.5px;
  display: inline-block;
  background-position: 50% center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid #f5f5f5;
  margin: 5px;
  position: relative;
}

.rdp-component-configurations-videos li video {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.rdp-component-configurations-videos li .play {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  /* background-image: url(./video_play.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60px;
  opacity: 0.4;
}

.rdp-component-configurations-videos li.active {
  border: 1px solid #7d7d7d;
  opacity: 0.5;
}

.rdp-component-configurations-footer {
  position: absolute;
  width: 100%;
  height: 60px;
  bottom: 0;
  text-align: right;
  padding: 9px 15px;
}

.rdp-component-configurations-submit {
  background: #2d7b45;
  color: #fff;
  border: 0;
  padding: 8px 30px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 30px;
  cursor: pointer;
}

.rdp-component-configurations-upload {
  border: 1px solid #e7e7e7;
  border-style: dashed;
  border-radius: 14px;
  position: relative;
  text-align: center;
  font-size: 15px;
  padding: 10px 0px;
  overflow: hidden;
}

.rdp-component-configurations-upload input {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.rdp-component-configurations-upload-progress {
  width: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #CCC;
  transition: all 0.2s linear;
  background: rgb(45, 123, 69, 0.2);
}

.rdp-component-configurations-preloader {
  position: absolute;
  top: 130px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-image: url("./preloader.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 180px;
}