/*region element max-width*/
/*SM*/
.element-image-1.max-w-sm figure img  {
  max-width: 760px;
}
/*MD*/
.element-image-1.max-w-md figure img  {
  max-width: 1160px;
}
/*LG*/
.element-image-1.max-w-lg figure img  {
  max-width: 1600px;
}
/*FULL*/
.element-image-1.max-w-full figure img  {
  max-width: calc(100% - 60px);
}
/*endregion*/


.element-image-1 {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;
}

.element-image-1:hover {
  /* border-top: 1px dashed #ccc; */
  /* border-bottom: 1px dashed #ccc; */
}

.element-image-1 figure img {
  width: 100%;
  /* max-width: 80%; */
  display: block;
  margin: auto;
}

.element-image-2 {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;
}

.element-image-2:hover {
  /* border-top: 1px dashed #ccc; */
  /* border-bottom: 1px dashed #ccc; */
}

.element-image-2 figure img {
  width: 100%;
  height: auto;
  display: block;
  margin: auto;
}

.element-image-3 {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: block;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.element-image-3:hover {
  /* border-top: 1px dashed #ccc; */
  /* border-bottom: 1px dashed #ccc; */
}

.element-image-3 .container {
  width: 80%;
  display: block;
  margin: auto;
}

.element-image-3 figure img {
  width: 50%;
  display: inline-block;
  margin: auto;
  vertical-align: middle;
}

.element-image-3 div.text {
  width: 50%;
  display: inline-block;
  margin: auto;
  vertical-align: middle;
  border: 0;
  padding-left: 20px;
  font-size: 20px;
  line-height: 30px;
  overflow: hidden;
}

.mobile .element-image-1  figure img {
  max-width: 100%;
}

.element-image-1-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
}

.element-image-1-modal .element-image-1-modal-header {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.element-image-1-modal-header-zoom-btns {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 60px;
  overflow: hidden;
}

.element-image-1-modal-header-zoom-btn {
  width: 50px;
  height: 60px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

.element-image-1-modal-header-zoom-btn svg {
  width: 20px;
  height: 100%;
}

.element-image-1-modal-header-close-btn {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  cursor: pointer;
}

.element-image-1-modal-header-close-btn svg {
  width: 20px;
  height: 100%;
}

.element-image-1-modal .element-image-1-modal-body {
  position: absolute;
  top: 60px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.element-image-1-modal .react-transform-wrapper {
  display: block;
  margin: auto;
}

.element-image-1-modal .react-transform-wrapper img {
  max-width: unset;
  height: 100%;
  width: 100%;
}

.element-image-1-modal .react-transform-component {
  transition: none;
}

.element-image-1 figure figcaption,
.element-image-2 figure figcaption,
.element-image-3 figure figcaption {
  max-width: 760px;
  margin: 10px auto;
}

.element-image-1 figure figcaption > div,
.element-image-2 figure figcaption > div,
.element-image-3 figure figcaption > div {
  margin: .75rem 8.3333333333% 0;
  border-bottom: 1px solid #ccc;
}

.element-image-1 figure figcaption > div strong,
.element-image-2 figure figcaption > div strong,
.element-image-3 figure figcaption > div strong {
  font-weight: bold !important;
}

@media screen and (max-width: 768px) {
  .element-image-1 img {
    max-width: calc(100% - 40px) !important;
  }
  .legend {
    max-width: calc(100% - 40px) !important;
  }
}