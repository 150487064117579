.rdp-composer-element-footer {
  position: absolute;
  height: 26px;
  bottom: -26px;
  /* bottom: 0; */
  left: 0;
  right: 0;
  transition: all 0.2s linear;
  z-index: 1;
  pointer-events: none;
  text-align: center;
}

*:hover > .rdp-composer-element-footer {
  bottom: 0;
}

.rdp-composer-element-footer .rdp-composer-element-footer-more-btn {
  /* position: absolute; */
  /* left: 10px; */
  background: #5d5d5d;
  width: 50px;
  height: 26px;
  cursor: pointer;
  padding: 0;
  border: 0;
  pointer-events: initial;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-image: url("./plus.svg");
  background-size: 18px;
  background-position: center;
  background-repeat: no-repeat;
}