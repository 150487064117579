/*region element max-width*/
/*SM*/
.element-flashcards-1.max-w-sm .element-flashcards-1-items  {
  max-width: 760px;
}
/*MD*/
.element-flashcards-1.max-w-md .element-flashcards-1-items  {
  max-width: 1160px;
}
/*LG*/
.element-flashcards-1.max-w-lg .element-flashcards-1-items  {
  max-width: 1600px;
}
/*FULL*/
.element-flashcards-1.max-w-full .element-flashcards-1-items  {
  max-width: calc(100% - 60px);
}
/*endregion*/

.element-flashcards-1 {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;
}

.element-flashcards-1-items {
  width: 100%;
  display: flex;
  margin: auto;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.element-flashcards-1-item {
  display: inline-block;
  overflow: hidden;
  position: relative;
  transition: all 0.2s linear;
  perspective: 1000px;
}

.element-flashcards-1-item-inner {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.element-flashcards-1-item-inner.flipped {
  transform: rotateY(180deg);
}

.element-flashcards-1-item-front, .element-flashcards-1-item-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.element-flashcards-1-item-data-text {
  padding: 10px;
}

.element-flashcards-1-item-front {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #313537;
  font-size: 25px;
  background-color: #FFFFFF;
  color: black;
  pointer-events: none;
  border: 1px solid #ededed;
  border-top: 3px solid #00643e;
}

.element-flashcards-1-item-back {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #313537;
  font-size: 25px;
  background-color: #fafafa;
  color: black;
  transform: rotateY(180deg);
  pointer-events: none;
  border: 1px solid #ededed;
  box-shadow: 0 0.4rem 1.2rem 0.2rem rgba(0,0,0,.05);
}

.element-flashcards-1-item.expanded {
  height: auto;
}

.element-flashcards-1-item-border {
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 5px;
  width: 3px;
  z-index: 1;
  display: none;
}

.element-flashcards-1-item.expanded .element-flashcards-1-item-border {
  display: block;
}

.element-flashcards-1-item-title {
  cursor: pointer;
  display: block;
  font-size: 18px;
  font-weight: bold;
  padding: 35px 30px;
}

.element-flashcards-1-item-text {
  padding: 30px;
  padding-top: 0;
}

.element-flashcards-1-item-text .ql-editor {
  padding: 0;
}

.element-flashcards-1-item-text .ql-editor strong {
  font-weight: bold;
}

.element-flashcards-1-item-text .ql-editor strong {
  font-weight: bold;
}

.element-flashcards-1-item-text .ql-editor em {
  font-style: italic;
}

.element-flashcards-1-item-text .ql-editor u {
  text-decoration: underline;
}

.element-flashcards-1-item-image {
  width: calc(100% - 60px);
  margin: 30px;
  margin-top: 0;
}

.element-flashcards-1-item-video {
  width: calc(100% - 60px);
  margin: 30px;
  margin-top: 0;
}

.mobile .element-flashcards-1 {
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (max-width: 768px) {
  .element-flashcards-1 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 600px) {
  .element-flashcards-1-items {
    display: block;
  }

  .element-flashcards-1-item {
    margin-bottom: 20px;
  }

  .element-flashcards-1-item:last-child {
    margin-bottom: 0px;
  }
}