.virtual-element-index-1 {
  background: #ececec;
  padding: 25px;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
}

.virtual-element-index-1-title {

}

.virtual-element-index-1-description {
  display: block;
  font-size: 11px;
  font-weight: bold;
  color: #5d5d5d;
  margin-top: 5px;
}