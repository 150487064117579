/*region element max-width*/
/*SM*/
.element-quote-3.max-w-sm .rdp-element-quote-3-container {
  max-width: 760px;
}
/*MD*/
.element-quote-3.max-w-md .rdp-element-quote-3-container {
  max-width: 1160px;
}
/*LG*/
.element-quote-3.max-w-lg .rdp-element-quote-3-container {
  max-width: 1600px;
}
/*FULL*/
.element-quote-3.max-w-full .rdp-element-quote-3-container {
  max-width: calc(100% - 60px);
}
/*endregion*/

.rdp-element-quote-3 {
  width: 100%;
  background-color: #FFFFFF;
  text-align: center;
  background-size: 100%;
  position: relative;
  overflow: hidden;
}

.rdp-element-quote-3-container {
  width: 100%;
  max-width: 760px;
  display: block;
  padding: 50px 0;
  text-align: left;
  margin: 30px auto;
}

.mobile .rdp-element-quote-3-container,
.tablet .rdp-element-quote-3-container {
  padding: 30px;
}

.rdp-element-quote-3-text-container {
  position: relative;
  width: calc(100% - 200px);
  display: inline-block;
  vertical-align: top;
  margin-left: 30px;
}

.mobile .rdp-element-quote-3-text-container-icon,
.tablet .rdp-element-quote-3-text-container-icon {
  left: -50px;
}

.mobile .rdp-element-quote-3-avatar,
.tablet .rdp-element-quote-3-avatar {
  width: 60px;
  height: 60px;
}

.mobile .rdp-element-quote-3-text-container,
.tablet .rdp-element-quote-3-text-container {
  width: calc(100% - 80px);
  margin-left: 20px;
}

.rdp-element-quote-3-text-container-icon {
  width: 120px;
  height: 120px;
  position: absolute;
  left: -70px;
  top: -60px;
}

.rdp-element-quote-3-text-container-icon svg {
  width: 100%;
  height: 100%;
}

.rdp-element-quote-3-text {
  color: #fff;
  width: 100%;
  display: block;
}

.rdp-element-quote-3-text .ql-editor {
  padding: 0;
  line-height: inherit;
  overflow: hidden;
}

.rdp-element-quote-3-text .ql-editor p:before,
.rdp-element-quote-3-text .ql-editor p:after {
  content: '"';
  color: #313537;
  font-size: 16px;
}

.rdp-element-quote-3-text .ql-editor * {
  line-height: 150%;
}

.rdp-element-quote-3-avatar {
  width: 170px;
  height: 170px;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  position: relative;
  z-index: 1;
}

.rdp-element-quote-3-avatar img {
  margin-left: 50%;
  margin-top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.rdp-element-quote-3-author-info {
  display: block;
  margin-top: 10px;
}

.rdp-element-quote-3-author-name {
  color: #fff;
  font-family: Prompt-Regular;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  /* display: inline-block; */
  /* vertical-align: middle; */
}

.rdp-element-quote-3-author-name .ql-editor {
  padding: 0;
  line-height: inherit;
  overflow: hidden;
  font-size: 17px;
}

.rdp-element-quote-3-author-name .ql-editor * {
  font-family: Lato-Regular!important;
}

.rdp-element-quote-3:hover {
  outline: 1px dashed #CCC!important;
  outline-offset: -1px;
}

@media screen and (max-width: 760px) {
  .rdp-element-quote-3-container {
    padding: 30px;
  }

  .rdp-element-quote-3-text-container-icon {
    left: -50px;
  }

  .rdp-element-quote-3-avatar {
    width: 60px;
    height: 60px;
  }

  .rdp-element-quote-3-text-container {
    width: calc(100% - 80px);
    margin-left: 20px;
  }
}