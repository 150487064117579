.rdp-composer-home {
  background: #fafafa;
}

.rdp-composer-home .rdp-component-preloader {
  z-index: 99999
}

.rdp-composer-home-compositions {
  background: #fafafa;
  position: absolute;
  top: 135px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
}

.btn-load-more { 
  color: #fff;
  padding: 0 20px;
  border: 1px solid #e9eaee;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  width: auto;
  background-color: #2d7b45;
  margin-top: 30px;
  margin-bottom: 50px;
  font-size: 11px;
}

.rdp-composer-home-composition {
  width: 260px;
  height: 190px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(199, 199, 199, 0.5);
  display: inline-block;
  margin: 10px;
  cursor: pointer;
  overflow: hidden;
  padding: 20px;
  position: relative;
  /* border-radius: 4px; */
  text-align: left;
  border-radius: 10px;
}

.rdp-composer-home-composition-data {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
}

.rdp-composer-home-composition-title {
  font-family: Lato-Bold;
  font-size: 15px;
  line-height: 17px;
  display: block;
  max-height: 78px;
  overflow: hidden;
}

.rdp-composer-home-composition-versions {
  font-family: Lato-Bold;
  font-size: 12px;
  display: inline-block;
  background: #e4f2ce;
  color: #479f26;
  padding: 5px 10px;
  border-radius: 20px;
  margin-top: 10px;
}

.rdp-composer-home-composition-author {
  left: 15px;
  bottom: 30px;
  position: absolute;
  font-size: 11px;
}

.rdp-composer-home-composition-author:before {
  content: "";
  background-image: url("./icon-author.svg");
  background-size: 10px;
  display: inline-block;
  width: 10px;
  height: 10px;    
  margin-right: 5px;
}

.rdp-composer-home-composition-date {
  left: 15px;
  bottom: 15px;
  position: absolute;
  font-size: 11px;
}

.rdp-composer-home-composition-date:before {
  content: "";
  background-image: url("./icon-date.svg");
  background-size: 10px;
  display: inline-block;
  width: 10px;
  height: 10px;    
  margin-right: 5px;
}

.rdp-composer-home-composition-actions {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
  background: #FFFFFF;
  opacity: 0;
  z-index: 2;
  pointer-events: none;
  transition: all 0.3s linear;
  box-shadow: 0px 0px 6px rgba(194, 194, 194, 0.5);
}

.rdp-composer-home-composition:hover .rdp-composer-home-composition-actions {
  opacity: 1;
  pointer-events: initial;
}

.rdp-composer-home-composition-actions li {
  width: 33.3%;
  display: inline-block;
  height: 60px;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.3s linear;
}

.rdp-composer-home-composition-actions li:hover {
  background-color: #f8f8f8;
}

.rdp-composer-home-composition-action-clone {
  background-image: url("./icon-clone.svg");
  background-size: 20px;
}

.rdp-composer-home-composition-action-preview {
  background-image: url("./icon-preview.svg");
  background-size: 23px;
}

.rdp-composer-home-composition-action-remove {
  background-image: url("./icon-remove.svg");
  background-size: 18px;
}

.rdp-composer-home-preloader {
  position: absolute;
  top: 135px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: #fafafa;
  background-image: url("./icon-preloader.gif");
  background-position: center;
  background-repeat: no-repeat;
}

.rdp-composer-home-import-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.rdp-composer-home-import-modal-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.rdp-composer-home-import-modal-window {
  width: 860px;
  height: 560px;
  background: #FFF;
  position: absolute;
  overflow: hidden;
  z-index: 1;
  top: 50%;
  margin-top: -280px;
  left: 50%;
  margin-left: -430px;
  border-radius: 4px;
}

.rdp-composer-home-import-modal-header {
  position: absolute;
  width: 100%;
  height: 60px;
  z-index: 100;
  box-shadow: 0px 0px 7px #ccc;
}

.rdp-composer-home-import-modal-header-title {
  font-weight: bold;
  line-height: 60px;
  padding-left: 20px;
}

.rdp-composer-home-import-modal-header-close {
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  background-image: url("./icon-close.svg");
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
}

.rdp-composer-home-import-modal-body {
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.rdp-composer-home-import-modal-body-sidebar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 260px;
  padding: 10px;
  box-shadow: 0px 0px 7px #ccc;
}

.rdp-composer-home-import-modal-body-sidebar-btn,
.rdp-composer-home-import-modal-body-login-submit {
  background-color: #4d7aff;
  width: 100%;
  height: 44px;
  border: 0;
  border-radius: 3px;
  color: #FFF;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 11px;
  cursor: pointer;
  position: relative;
  padding-left: 54px;
}

.rdp-composer-home-import-modal-body-sidebar-btn:before,
.rdp-composer-home-import-modal-body-login-submit:before {
  content: "";
  background-image: url("./icon-connect.svg");
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 54px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  border-right: 1px solid #85a3ff;
}

.rdp-composer-home-import-modal-body-login {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 260px;
  padding: 10px;
  z-index: 10;
  background-color: #FFF;
  box-shadow: 0px 0px 7px #ccc;
}

.rdp-composer-home-import-modal-body-login-instructions {
  font-weight: bold;
  display: block;
  margin-bottom: 20px;
  line-height: 17px;
  font-size: 12px;
}

.rdp-composer-home-import-modal-body-login-label {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  margin-bottom: 5px;
}

.rdp-composer-home-import-modal-body-login-input {
  width: 100%;
  height: 40px;
  display: block;
  margin-bottom: 15px;
  border: 1px solid #d8d8d8;
  padding-left: 10px;
}