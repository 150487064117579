.rdp-composer-structure {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.rdp-composer-structure * {
  /* font-family: Merriweather-Regular; */
  font-family: Lato-Regular;
  transition: all 0.2s ease-in-out;
}

.lato * { font-family: Lato-Regular; }
.lato b { font-family: Lato-Bold; }

.bradesco-sans * { font-family: BradescoSans-Regular; }
.bradesco-sans b { font-family: BradescoSans-Bold; }

.helvetica-neue * { font-family: HelveticaNeue-Regular; }
.helvetica-neue b { font-family: HelveticaNeue-Bold; }

.lora * { font-family: Lora-Regular; }
.lora b { font-family: Lora-Bold; }

.merriweather * { font-family: Merriweather-Regular; }
.merriweather b { font-family: Merriweather-Bold; }

.open-sans * { font-family: OpenSans-Regular; }
.open-sans b { font-family: OpenSans-Bold; }

.open-sans-condensed * { font-family: OpenSansCondensed-Light; }
.open-sans-condensed b { font-family: OpenSansCondensed-Bold; }

.prompt * { font-family: Prompt-Regular; }
.prompt b { font-family: Prompt-Bold; }

.product-sans * { font-family:  ProductSans-Regular; }
.product-sans  b { font-family:  ProductSans-Bold; }

.roboto * { font-family: Roboto-Regular; }
.roboto b { font-family: Roboto-Bold; }

.roboto-condensed * { font-family: RobotoCondensed-Regular; }
.roboto-condensed b { font-family: RobotoCondensed-Bold; }

.roboto-slab * { font-family: RobotoSlab-Regular; }
.roboto-slab b { font-family: RobotoSlab-Bold; }

.montserrat { font-family: Montserrat-Regular; }
.montserrat * { font-family: Montserrat-Regular; }
.montserrat b { font-family: Montserrat-Bold; }

.objective { font-family: Objective-Regular; }
.objective * { font-family: Objective-Regular; }
.objective b { font-family: Objective-Bold; }

.ubuntu { font-family: Ubuntu-Regular; }
.ubuntu * { font-family: Ubuntu-Regular; }
.ubuntu b { font-family: Ubuntu-Bold; }

.proxima-nova { font-family: ProximaNova-Regular; }
.proxima-nova * { font-family: ProximaNova-Regular; }
.proxima-nova b { font-family: ProximaNova-Bold; }

.grueber { font-family: Grueber-Regular; }
.grueber * { font-family: Grueber-Regular; }
.grueber b { font-family: Grueber-Bold; }

.itau-display-pro { font-family: ItauDisplayPro-Regular; }
.itau-display-pro * { font-family: ItauDisplayPro-Regular; }
.itau-display-pro b { font-family: ItauDisplayPro-Bold; }

.itau-text-pro { font-family: ItauTextPro-Regular; }
.itau-text-pro * { font-family: ItauTextPro-Regular; }
.itau-text-pro b { font-family: ItauTextPro-Bold; }

.inter-black { font-family: Inter-Black; }
.inter-black * { font-family: Inter-Black; }
.inter-semibold { font-family: Inter-Semibold; }
.inter-semibold * { font-family: Inter-Semibold; }
.inter-bold { font-family: Inter-Bold; }
.inter-bold * { font-family: Inter-Bold; }
.inter-extrabold { font-family: Inter-Extrabold; }
.inter-extrabold * { font-family: Inter-Extrabold; }
.inter-medium { font-family: Inter-Medium; }
.inter-medium * { font-family: Inter-Medium; }
.inter-regular { font-family: Inter-Regular; }
.inter-regular * { font-family: Inter-Regular; }
.inter-light { font-family: Inter-Light; }
.inter-light * { font-family: Inter-Light; }
.inter-extralight { font-family: Inter-ExtraLight; }
.inter-extralight * { font-family: Inter-ExtraLight; }
.inter-thin { font-family: Inter-Thin; }
.inter-thin * { font-family: Inter-Thin; }

.sharp-sans { font-family: Sharp-Sans; }
.sharp-sans * { font-family: Sharp-Sans; }
.sharp-sans b { font-family: Sharp-Sans-Bold; }
.sharp-sans-thin  { font-family: Sharp-Sans-Thin; }
.sharp-sans-thin * { font-family: Sharp-Sans-Thin; }
.sharp-sans-semibold  { font-family: Sharp-Sans-Semibold; }
.sharp-sans-semibold * { font-family: Sharp-Sans-Semibold; }
.sharp-sans-medium  { font-family: Sharp-Sans-Medium; }
.sharp-sans-medium * { font-family: Sharp-Sans-Medium; }
.sharp-sans-light { font-family: Sharp-Sans-Light; }
.sharp-sans-light * { font-family: Sharp-Sans-Light; }
.sharp-sans-extrabold { font-family: Sharp-Sans-Extrabold; }
.sharp-sans-extrabold * { font-family: Sharp-Sans-Extrabold; }
.sharp-sans-bold { font-family: Sharp-Sans-Bold; }
.sharp-sans-bold * { font-family: Sharp-Sans-Bold; }

.duplicate-ionic { font-family: Duplicate-Ionic; }
.duplicate-ionic * { font-family: Duplicate-Ionic; }
.duplicate-ionic b { font-family: Duplicate-Ionic-Bold; }
.duplicate-ionic-heavy { font-family: Duplicate-Ionic-Heavy; }
.duplicate-ionic-heavy * { font-family: Duplicate-Ionic-Heavy; }
.duplicate-ionic-light { font-family: Duplicate-Ionic-Light; }
.duplicate-ionic-light * { font-family: Duplicate-Ionic-Light; }
.duplicate-ionic-medium { font-family: Duplicate-Ionic-Medium; }
.duplicate-ionic-medium * { font-family: Duplicate-Ionic-Medium; }
.duplicate-ionic-thin { font-family: Duplicate-Ionic-Thin; }
.duplicate-ionic-thin * { font-family: Duplicate-Ionic-Thin; }

.flama { font-family: Flama; }
.flama * { font-family: Flama; }
.flama b { font-family: Flama-Bold; }
.flama-bold { font-family: Flama-Bold; }
.flama-bold * { font-family: Flama-Bold; }
.flama-bold b { font-family: Flama-Bold; }
.flama-condensed-bold { font-family: Flama-Condensed-Bold; }
.flama-condensed-bold * { font-family: Flama-Condensed-Bold; }
.flama-condensed-bold b { font-family: Flama-Condensed-Bold; }
.flama-condensed-light { font-family: Flama-Condensed-Light; }
.flama-condensed-light * { font-family: Flama-Condensed-Light; }

.rdp-composer-structure > div:first-child {
  /* padding-top: 0; */
  border-top: 0;
}

.rdp-composer-structure *:hover:first-child {
  /* border-top: 0; */
}

.element-text-4 {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;
}

.element-text-4:hover {
  outline: 1px dashed #CCC!important;
  outline-offset: -1px;
}

.element-text-4 div.title {
  display: block;
  margin: auto;
  width: 80%;
  border: 0;
  resize: none;
  font-size: 30px;
  line-height: 40px;
  font-weight: bold;
  overflow: hidden;
}

.element-text-5 {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;
}

.element-text-5:hover {
  outline: 1px dashed #CCC!important;
  outline-offset: -1px;
}

.element-text-5 div.title {
  display: block;
  margin: auto;
  width: 80%;
  border: 0;
  resize: none;
  font-size: 20px;
  line-height: 40px;
  font-weight: bold;
  overflow: hidden;
}

.element-text-6 {
  padding-top: 30px;
  padding-bottom: 30px;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.element-text-6:hover {
  outline: 1px dashed #CCC!important;
  outline-offset: -1px;
}

.element-text-6 div.left, .element-text-6 div.right {
  display: inline-block;
  vertical-align: top;
  margin: auto;
  width: 40%;
  border: 0;
  resize: none;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  text-align: left;
  font-size: 18px;
  line-height: 26px;
}

.element-text-6 div.left {
  padding-right: 5px;
}

.element-text-6 div.right {
  padding-left: 5px;
}

.element-image-4 {
  height: 400px;
  background-position: 50%;
  background-size: cover;
  overflow: hidden;
  position: relative;
}

.element-image-overlay {
  width: 100%;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
}

.element-image-4 .container {
  width: 80%;
  display: block;
  margin: auto;
  position: absolute;
  left: 10%;
}

.element-image-4 .container div {
  width: 60%;
  vertical-align: middle;
  font-size: 28px;
  line-height: 36px;
  resize: none;
  overflow: hidden;
  background: transparent;
  display: inline-block;
  border: 0;
  color: #fff;
}

.element-image-4 .container .ruler {
  height: 400px;
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  background: transparent;
}

.element-multimedia-2 {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: block;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.element-multimedia-2:hover {
  outline: 1px dashed #CCC!important;
  outline-offset: -1px;
}

.element-multimedia-2 video {
  display: block;
  width: 80%;
  margin: auto;
}

.element-gallery-2 {
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  display: block;
  margin: auto;
}

.element-gallery-2:hover {
  outline: 1px dashed #CCC!important;
  outline-offset: -1px;
}

.element-gallery-2 .container {
  width: 80%;
  display: block;
  margin: auto;
}

.element-gallery-2 .container .first-image {
  width: calc(50% - 10px);
  height: 425px;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
}

.element-gallery-2 .container .first-image img {
  height: 100%;
}

.element-gallery-2 .container .second-image {
  width: calc(50% - 10px);
  height: 425px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
  overflow: hidden;
}

.element-gallery-2 .container .second-image img {
  height: 100%;
}

.rdp-composer-structure-message {
  background-image: url("./icon-no-content-2.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 140px;
  position: absolute;
  top: 80px;
  bottom: 0;
  left: 0;
  right: 0;
}

.rdp-composer-structure-message h1 {
  text-align: center;
  width: 100%;
  position: absolute;
  top: calc(50% + 80px);
  font-size: 18px;
  font-weight: bold;
  color: #bebebe;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media screen and (max-width: 768px) {
  .element-multimedia-2 video {
    width: 100%;
  }
}

.rdp-composer-element-temp-slot {
  background: #e4e4e4;
  height: 120px;
  text-align: center;
  line-height: 120px;
  text-transform: uppercase;
  font-weight: bold;
  color: #2d7b45;
}